const FieldCheckBox = ({ isChecked = false, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={
        isChecked
          ? "w-[17px] h-[17px]  my-auto mr-2 bg-scbPrimary border border-white"
          : "w-[17px] h-[17px]  my-auto mr-2 border border-scbPrimary bg-white "
      }
    />
  );
};

export default FieldCheckBox;
