import React, { useState, Fragment } from "react";
import BreadCrumbMain from "../../components/breadcrumb/BreadCrumb.Main";
import HomeCard from "./render/Home.Card";
import { InfoModal } from "../../components/modal/info";
import HomeLoadNoti from "./functions/Home.LoadNoti";

const HomeMain = ({ CARDS }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [countNoti, setCountNoti] = useState(0);

  React.useEffect(() => {
    HomeLoadNoti(setCountNoti);
    setShowPopup(true);
  }, []);

  return (
    <div className="px-90px">
      <div className="flex justify-between ">
        <BreadCrumbMain />
        <img
          onClick={() => {
            setShowPopup(true);
          }}
          src="/icon/information.png"
          className="w-[28px] h-[28px] my-auto mr-10 cursor-pointer absolute right-20 top-48  "
        />
      </div>
      <div className="grid grid-cols-5 mx-auto max-w-7xl gap-6 pb-20 mt-20">
        {CARDS?.map((card, index) => {
          return (
            <HomeCard
              key={`key-of-card-${index}`}
              image={card?.image}
              name={card?.name}
              path={card?.path}
              disabled={card?.disabled}
              showNotif={index === 0}
              countNoti={countNoti}
            />
          );
        })}
        <div className="flex justify-center space-x-3  col-span-full">
          {new Array(3).fill(0).map((_, index) => {
            return (
              <div
                key={`dot-${index}`}
                className="bg-gray-50 rounded-full w-4 h-4 cursor-pointer border border-gray-400"
              />
            );
          })}
        </div>
      </div>
      {showPopup && (
        <InfoModal setShowPopup={setShowPopup} showPopup={showPopup} />
      )}
    </div>
  );
};

export default HomeMain;
