import React from "react";
import {
  CONSTANT_PAGE_PROCESS,
  TYPE_NEW,
  TYPE_UPDATED,
} from "../../utils/useConstants";

const WarningMain = ({ data, isMyApprover }) => {
  const [open, setOpen] = React.useState(true);

  const isShowByStatus =
    data?.tran_status === TYPE_NEW || data?.tran_status === TYPE_UPDATED;
  if (
    open &&
    data?.old_process_name !== data?.process_name &&
    isMyApprover &&
    isShowByStatus
  ) {
    return (
      <div
        class="flex justify-between bg-[#F5EBB2] text-[#242424] rounded-lg p-4 mb-4 text-sm   font-bold "
        role="alert"
      >
        <div className="flex">
          <div className="w-1 bg-[#FAD271] mr-4 " />
          <img className="w-[28px] h-[28px]" src="/icon/warning.png" />
          <div className="my-auto text-[28px] ml-4">
            {CONSTANT_PAGE_PROCESS.WARNING_CHANGE_NAME}
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
          className="cursor-pointer my-auto"
        >
          x
        </div>
      </div>
    );
  } else {
    return <React.Fragment />;
  }
};

export default WarningMain;
