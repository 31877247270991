import { FunctionGroupingArrayByKey } from "radium-omnibus";
import api from "./useApi";

const FunctionFirstLoadPromise = async (setValue) => {
  try {
    const {
      data: { data: setting },
    } = await api.api_master_setting_options();
    const {
      data: { data: organiztions },
    } = await api.api_master_organiztions();

    let thisSetting = {};

    const groupData = FunctionGroupingArrayByKey(setting, "setting_group");

    groupData.forEach((item) => {
      thisSetting[item.key] = item.value;
    });

    let thisOptions = {
      ...thisSetting,
      organiztions: organiztions,
    };

    setValue(thisOptions);
  } catch (error) {
    console.log("FunctionFirstLoadPromise : ", error);
  }
};

export default FunctionFirstLoadPromise;
