import React from "react";
import { Dialog, Transition } from "@headlessui/react";

const ModalMain = ({
  open = false,
  setOpen = () => {},
  title = "",
  body = false,
  footer = true,
  labelCancel = "CANCEL",
  labelSubmit = "SUBMIT",
  onClickSubmit = () => {},
  maxWidth = " max-w-6xl",
  onClose = false,
}) => {
  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        onClose={
          onClose
            ? () => {
                onClose();
              }
            : () => setOpen(false)
        }
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={
                  "w-full p-12 rounded-3xl " +
                  maxWidth +
                  " transform   bg-white  text-left align-middle shadow-xl transition-all"
                }
              >
                <Dialog.Title
                  as="h3"
                  className="font-bold text-32px w-full text-center   leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                {body}
                {footer && <div className="h-20"></div>}
                {footer && (
                  <div className="absolute bottom-12 left-1/2 transform -translate-x-1/2 ">
                    <div className="flex  space-x-4  pt-10">
                      <button
                        onClick={() => {
                          setOpen(false);
                        }}
                        className=" shadow-xl font-bold rounded-md border-scbPrimary shaodow-scbPrimary  text-24px text-scbPrimary  px-3 w-130px cursor-pointer"
                      >
                        {labelCancel}
                      </button>
                      <button
                        onClick={() => {
                          onClickSubmit();
                        }}
                        className="bg-scbPrimary font-bold rounded-md shadow-xl text-white  px-3 text-24px w-130px cursor-pointer"
                      >
                        {labelSubmit}
                      </button>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalMain;
