import axios from "axios";
import {
  FunctionGetTokenFromLocalStorage,
  SystemLogout,
} from "../systems/Systems.StorageUser";
import { apiBaseURL } from "./useConfig";

const API_NON_PROTECT = axios.create({
  baseURL: apiBaseURL,
});

const API = axios.create({
  baseURL: apiBaseURL,
});

const requestInterceptors = (configAxios) => {
  const access_token = sessionStorage.getItem("access_token");

  if (access_token) {
    configAxios.headers = {
      ...configAxios.headers,
      Authorization: `Bearer ${access_token}`,
    };
  }
  return configAxios;
};

const responseInterceptors = (response) => {
  return response;
};

const responseErrorInterceptors = async (error) => {
  const errorResponse = error?.response || { response: "Network Error" };

  if (errorResponse?.status === 401) {
    const token = FunctionGetTokenFromLocalStorage();
    if (token.refresh_token !== undefined && token.refresh_token !== null) {
      await SystemLogout();
    }
    if (errorResponse?.data?.status?.code !== undefined) {
      alert(errorResponse?.data?.status?.description);
      await SystemLogout();
    } else {
      alert("ไม่ได้ใช้งานภายในเวลาที่กำหนด");
      await SystemLogout();
    }
  } else if (errorResponse?.status === 404) {
    // alert("NOT_FOUND");
    console.error("NOT_FOUND");
    // await SystemLogout();
  }

  return Promise.reject(error);
};

API.interceptors.request.use(requestInterceptors);

API.interceptors.response.use(responseInterceptors, responseErrorInterceptors);

const api_process_search = ({ params }) => {
  return API.get(`/process${params}`);
};

const api_process_by_id = ({ id }) => {
  return API.get(`/process/${id}`);
};

const api_process_create = ({ data }) => {
  return API.post("/process", data);
};

const api_process_update = ({ id, data }) => {
  return API.put(`/process/${id}`, data);
};

const api_process_approve = ({ id }) => {
  return API.put(`/process/${id}/approve`);
};

const api_process_approve_all = ({ tran_id }) => {
  return API.put(`/process/approve/all`, { tran_id });
};

const api_process_complete = ({ id }) => {
  return API.put(`/process/${id}/complete`);
};

const api_process_reject = ({ id, data }) => {
  return API.put(`/process/${id}/reject`, data);
};

const api_master_applocation = () => {
  return API.get(`/master/applications`);
};

const api_master_products = () => {
  return API.get(`/master/products`);
};

const api_master_organiztions = () => {
  return API.get(`/master/occode`);
};

const api_master_subprocess = (id) => {
  return API.get(`/master/subprocess?${id}`);
};

const api_master_process = (code) => {
  return API.get(`/master/process?org_id=${code}`);
};

const api_master_employee = (id) => {
  return API.get(`/master/employee?org_id=${id}`);
};

const api_authen_login = ({ username, password }) => {
  return API_NON_PROTECT.post(`/auth/login`, {
    user_id: username,
    password: password,
  });
};

const api_authen_refetch_token = ({ refresh_token }) => {
  return API.post(`/auth/refresh`, { refresh_token });
};

const api_authen_logout = ({ refresh_token }) => {
  return API.post(`/auth/logout`, { refresh_token });
};

const api_authen_profile = () => {
  return API.get(`/userprofile`);
};

const api_master_frequency = () => {
  return API.get(`master/setting/FREQUENCY`);
};

const api_master_in_and_out = () => {
  return API.get(`master/setting/IO_TYPE`);
};

const api_master_process_cat = () => {
  return API.get(`master/setting/PROCESS_CAT`);
};

const api_master_sub_process_type = () => {
  return API.get(`master/setting/SUB_PROCESS_TYPE`);
};

const api_master_setting_options = () => {
  return API.get(`master/setting`);
};

const api_master_notification = () => {
  return API.get(`notification`);
};

const api = {
  api_process_search,
  api_process_by_id,
  api_process_create,
  api_process_update,
  api_process_approve,
  api_process_approve_all,
  api_process_complete,
  api_process_reject,
  api_master_applocation,
  api_master_products,
  api_master_organiztions,
  api_master_subprocess,
  api_master_process,
  api_master_employee,
  api_master_frequency,
  api_master_in_and_out,
  api_master_process_cat,
  api_master_sub_process_type,
  api_authen_login,
  api_authen_refetch_token,
  api_authen_logout,
  api_authen_profile,
  api_master_notification,
  api_master_setting_options,
};

export default api;
