import { FunctionGetObjectIsError, FunctionUseParams } from "radium-omnibus";
import api from "../../../utils/useApi";
import {
  DEFAULT_RECORD_PER_PAGE,
  TYPE_NEW,
  TYPE_UPDATED,
  TYPE_APPROVED,
  TYPE_COMPLETED,
  TYPE_REJECTED,
} from "../../../utils/useConstants";
import { FunctionCheckHasValue } from "../../../utils/useFunctions";

const ProcessLoadDataTable = async (
  setData,
  search,
  page,
  setLoading,
  setHasError,
  hasSearch
) => {
  try {
    setLoading(true);

    const reset = sessionStorage.getItem("check-status") === "reset";
    let loadParams = JSON.parse(sessionStorage.getItem("process_search"));

    console.log("CHECK POINT SERACH : ", {
      search,
      loadParams,
      reset,
      hasSearch,
    });

    if (loadParams && !reset && !hasSearch) {
      const { data } = await api.api_process_search({
        params: FunctionUseParams(loadParams),
      });

      setData(data);
    } else {
      let params = {
        page_index: page,
        record_per_page: DEFAULT_RECORD_PER_PAGE,
        init: "Y",
      };

      const thisError = FunctionGetObjectIsError(search);

      if (thisError?.length > 0) {
        setHasError(true);
        return;
      }

      if (FunctionCheckHasValue(search?.org_id) && search?.org_id !== "") {
        params = {
          ...params,
          org_id: search?.org_id,
        };
        // delete params?.init;
      }

      if (search?.process_name) {
        params = {
          ...params,
          process_name: search?.process_name,
        };
        // delete params?.init;
      }

      if (search?.tran_status === "All") {
        delete search?.tran_status;
      }

      if (
        [
          TYPE_NEW,
          TYPE_UPDATED,
          TYPE_APPROVED,
          TYPE_REJECTED,
          TYPE_COMPLETED,
        ].includes(search?.tran_status)
      ) {
        params = {
          ...params,
          tran_status: search?.tran_status,
        };
        // delete params?.init;
      }

      if (hasSearch) {
        delete params?.init;
      }

      sessionStorage.setItem("process_search", JSON.stringify(params));

      const { data } = await api.api_process_search({
        params: FunctionUseParams(params),
      });

      setData(data);
    }
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};

export default ProcessLoadDataTable;
