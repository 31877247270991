export const ICON_ARROW_DOWN = ({ onClick, className, fill = "none" }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path fill="currentColor" d="m12 15l-5-5h10Z" />
    </svg>
  );
};

export const ICON_CHEVRON_LEFT = ({
  onClick,
  className = "w-6 h-6 text-gray-500",
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      fill={fill}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  );
};

export const ICON_CHEVRON_RIGHT = ({
  onClick,
  className = "w-6 h-6 text-gray-500",
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      fill={fill}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 4.5l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};

export const ICONS_CHEVRON_DOWN = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
      fill={fill}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
};

export const ICONS_CHEVRON_UP = ({
  className = "",
  style = {
    width: "24px",
    height: "24px",
  },
  fill = "none",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
      style={style}
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
    </svg>
  );
};

export const ICONS_WARNING = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-16 h-16 bg-yellow-500 rounded-full text-white mx-auto"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
      />
    </svg>
  );
};

export const ICONS_WARNING_TRIANGLE = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
  );
};

export const ICON_DATA_NOT_FOUND = ({
  className = "m-auto mb-5px w-[60px] h-[60px]",
}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6666 14.6667H18.6133L18.24 14.3067C19.5466 12.7867 20.3333 10.8133 20.3333 8.66667C20.3333 3.88 16.4533 0 11.6666 0C7.10662 0 3.37329 3.52 3.03996 8H5.73329C6.06662 5 8.57329 2.66667 11.6666 2.66667C14.9866 2.66667 17.6666 5.34667 17.6666 8.66667C17.6666 11.9867 14.9866 14.6667 11.6666 14.6667C11.44 14.6667 11.2266 14.6267 11 14.6V17.2933C11.2266 17.32 11.44 17.3333 11.6666 17.3333C13.8133 17.3333 15.7866 16.5467 17.3066 15.24L17.6666 15.6133V16.6667L24.3333 23.32L26.32 21.3333L19.6666 14.6667Z"
        fill="black"
        fill-opacity="0.54"
      />
      <path
        d="M7.62659 10.4271L4.33326 13.7204L1.03993 10.4271L0.0932617 11.3738L3.3866 14.6671L0.0932617 17.9604L1.03993 18.9071L4.33326 15.6138L7.62659 18.9071L8.57326 17.9604L5.27993 14.6671L8.57326 11.3738L7.62659 10.4271Z"
        fill="black"
        fill-opacity="0.54"
      />
    </svg>
  );
};
