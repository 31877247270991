const FunctionCheckErrorSpaceBar = (value) => {
  const thisValue = value?.trim();

  if (thisValue?.length >= 3 || value?.length === 0) {
    return false;
  } else {
    return true;
  }
};

const FunctionCheckErrorSpaceBarOnly = (value) => {
  const thisValue = value?.trim();

  if (thisValue?.length > 0 || value?.length === 0) {
    return false;
  } else {
    return true;
  }
};

export const FunctionErrorCustoms = ({ value }) => {
  const isErrorSpaceBar = FunctionCheckErrorSpaceBar(value);

  if (isErrorSpaceBar) {
    return "Please enter a value";
  } else {
    return "";
  }
};

export const FunctionErrorCustomsSpaceBar = ({ value }) => {
  const isErrorSpaceBarOnly = FunctionCheckErrorSpaceBarOnly(value);

  if (isErrorSpaceBarOnly) {
    return "Please enter a value";
  } else {
    return "";
  }
};
