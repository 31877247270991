import React from "react";
import { SystemStoreLoading } from "../../systems/Systems.Loading";
import {
  DEFAULT_RECORD_PER_PAGE,
  ERROR_TEXT_DATA_NOT_FOUND,
} from "../../utils/useConstants";
import { ICON_DATA_NOT_FOUND } from "../../utils/useIcons";
import PaginationMain from "../pagination/Pagination.Main";

const TableMain = ({
  headers = [],
  dataCells = [],
  onClickRow = () => {},
  setPage,
  page,
  pagination,
}) => {
  const loading = SystemStoreLoading((state) => state.loading);

  return (
    <div className="overflow-x-auto shadow-md pb-6">
      <div className="  flex items-center justify-center overflow-hidden">
        <div className="w-full relative">
          <div className=" mb-6 h-table overflow-y-auto">
            <table className="min-w-max w-full table-auto ">
              <thead className="sticky top-0">
                <tr className="bg-scbPrimary rounded-3xl text-white font-light text-28px leading-normal ">
                  {headers?.map((header, index) => {
                    return (
                      <th
                        key={`key-of-header-${index}`}
                        className={
                          "py-3  px-6 text-center " +
                          (index === 0 ? "rounded-l-xl " : "") +
                          (index === headers.length - 1 ? "rounded-r-xl" : "")
                        }
                      >
                        {header?.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="text-gray-600 text-30px font-light  ">
                {loading ? (
                  <React.Fragment />
                ) : dataCells?.length > 0 ? (
                  <React.Fragment>
                    {dataCells?.map((dataCell, index) => {
                      return (
                        <React.Fragment key={`key-of-data-cell-${index}`}>
                          <tr className="border-b border-gray-200  hover:bg-gray-100">
                            {headers?.map((header, hindex) => {
                              return (
                                <th
                                  onClick={() => {
                                    onClickRow(dataCell);
                                  }}
                                  key={`key-of-cell-${hindex}`}
                                  className={`${
                                    header?.textAlign ?? ""
                                  } . py-3 px-6 cursor-pointer h-10`}
                                >
                                  {typeof dataCell[header?.key] === "function"
                                    ? dataCell[header?.key]({
                                        dataCell: dataCell,
                                      })
                                    : dataCell[header?.key]}
                                </th>
                              );
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div id="NoRecordFound" className="m-auto pt-xl">
                      <div className="text-[40px]  w-full text-center text-textsecoundary">
                        <ICON_DATA_NOT_FOUND />
                        {ERROR_TEXT_DATA_NOT_FOUND}
                      </div>
                    </div>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination && (
        <div className="mt-4">
          <PaginationMain
            amount={DEFAULT_RECORD_PER_PAGE} // แต่ละหน้าว่ามีกี่แถว
            count={pagination?.total_records || 0} // data ทั้งหมดที่มีใน database
            value={page}
            setValue={setPage}
          />
        </div>
      )}
    </div>
  );
};

export default TableMain;
