import { FunctionZeroPad } from "radium-omnibus";

export const FunctionChangeSnakeCaseToNormalCase = (string) => {
  const first = string?.slice(0, 1).toLocaleUpperCase();
  const last = string.replace(/([-_][a-z])/g, (group) => {
    return group.replace("-", " ").replace("_", " ");
  });
  return first + last.slice(1);
};

export const FunctionParseJwt = (token) => {
  if (!token) return null;
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const FunctionFormatDate = (date) => {
  const objectDate = new Date(date);
  const splitDate = objectDate.toLocaleDateString("en-US").split("/");
  // const result = `${FunctionZeroPad(Number(splitDate[2]), 4)}-${FunctionZeroPad(
  //   Number(splitDate[0]),
  //   2
  // )}-${FunctionZeroPad(Number(splitDate[1]), 2)}`; YYYY-MM-DD

  const result = `${FunctionZeroPad(Number(splitDate[1]), 2)}-${FunctionZeroPad(
    Number(splitDate[0]),
    2
  )}-${FunctionZeroPad(Number(splitDate[2]), 4)}`; // DD-MM-YYYY

  return result;
};

export const FunctionFormatDateYYYYMMDD = (date) => {
  const objectDate = new Date(date);
  const splitDate = objectDate.toLocaleDateString("en-US").split("/");
  const result = `${FunctionZeroPad(Number(splitDate[2]), 4)}-${FunctionZeroPad(
    Number(splitDate[0]),
    2
  )}-${FunctionZeroPad(Number(splitDate[1]), 2)}`; //YYYY-MM-DD

  // const result = `${FunctionZeroPad(Number(splitDate[1]), 2)}-${FunctionZeroPad(
  //   Number(splitDate[0]),
  //   2
  // )}-${FunctionZeroPad(Number(splitDate[2]), 4)}`; // DD-MM-YYYY

  return result;
};
export const FunctionFormatDateWithTime = (date) => {
  const objectDate = new Date(date);
  const splitDate = objectDate.toLocaleDateString("en-US").split("/");
  const splitTime = objectDate.toLocaleTimeString("en-US").split(":");
  const result = `${FunctionZeroPad(Number(splitDate[2]), 4)}-${FunctionZeroPad(
    Number(splitDate[0]),
    2
  )}-${FunctionZeroPad(Number(splitDate[1]), 2)} ${FunctionZeroPad(
    Number(splitTime[0]),
    2
  )}:${FunctionZeroPad(Number(splitTime[1]), 2)}:${FunctionZeroPad(
    Number(splitTime[2].split(" ")[0]),
    2
  )}`;

  return result;
};

export const FunctionCheckHasValue = (value) => {
  if (value?.error) {
    return false;
  } else if (
    value === null ||
    value === undefined ||
    value >= 0 ||
    value === "" ||
    value === false ||
    value === true ||
    value === "undefined" ||
    value
  ) {
    return true;
  } else {
    return false;
  }
};

export const FunctionFormatNewLine = (input) => {
  if (input) {
    const thisInput = String(input)?.split("\n");
    return (
      <div>
        {thisInput?.map((i) => {
          return <div>{i}</div>;
        })}
      </div>
    );
  } else {
    return "";
  }
};

export const FunctionCheckIsSuperAdmin = (user) => {
  const isSuperAdmin =
    (user?.oc_code === "7214" && user?.org_type === "Division") ||
    user?.role === "OPRISK APPROVAL";
  return isSuperAdmin;
};
