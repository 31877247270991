import { FunctionCloneDeep } from "radium-omnibus";
import React from "react";
import FieldCheckBox from "../../../components/fields/FieldCheckBox";
import {
  TYPE_APPROVED,
  TYPE_NEW,
  TYPE_REJECTED,
  TYPE_UPDATED,
} from "../../../utils/useConstants";
import {
  FunctionCheckIsSuperAdmin,
  FunctionFormatDateYYYYMMDD,
} from "../../../utils/useFunctions";

const ProcessFormatDataTable = (
  data = [],
  navigate,
  isSelect = false,
  setDataTable,
  user,
  page
) => {
  if (data?.length > 0) {
    const result = data?.map((item, index) => {
      const isApprover = item?.approver_id === user?.user_id;
      const isMyRequest = item?.request_name === user?.user_name;
      const isSuperAdmin = FunctionCheckIsSuperAdmin(user);
      const isShowPencil =
        ((item.tran_status === TYPE_REJECTED ||
          item.tran_status === TYPE_NEW ||
          item.tran_status === TYPE_UPDATED) &&
          isMyRequest) ||
        (isApprover &&
          (item.tran_status === TYPE_NEW ||
            item.tran_status === TYPE_UPDATED)) ||
        (isSuperAdmin && item.tran_status === TYPE_APPROVED);

      let object = {
        ...item,
        no: index + 1 + (page - 1) * 10,
        last_upd_datetime: FunctionFormatDateYYYYMMDD(item?.last_upd_datetime),
        tran_status:
          item.tran_status === TYPE_REJECTED ? (
            <div className="text-red">{TYPE_REJECTED}</div>
          ) : (
            item.tran_status
          ),
        action: isShowPencil ? (
          <img
            src="/icon/edit.png"
            className="w-4 h-4 mx-auto"
            onClick={() => {
              sessionStorage.setItem("check-status", "non-reset");
              navigate(
                `/process_create_and_maintenance/process/${item?.tran_id}`
              );
            }}
          />
        ) : (
          <img
            onClick={() => {
              sessionStorage.setItem("check-status", "non-reset");
              navigate(
                `/process_create_and_maintenance/process/${item?.tran_id}`
              );
            }}
            src="/icon/search.png"
            className="w-4 h-4 mx-auto"
          />
        ),
      };
      if (isSelect) {
        object = {
          ...object,
          selected:
            (object?.tran_status === TYPE_NEW ||
              object?.tran_status === TYPE_UPDATED) &&
            isApprover ? (
              <div className="flex justify-center">
                <FieldCheckBox
                  isChecked={item?.selected}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDataTable((prev) => {
                      const cloneData = FunctionCloneDeep([...prev]);
                      cloneData[index].selected = !cloneData[index].selected;
                      return cloneData;
                    });
                  }}
                />
              </div>
            ) : (
              <React.Fragment />
            ),
        };
      }
      return object;
    });
    return result;
  } else {
    return [];
  }
};
export default ProcessFormatDataTable;
