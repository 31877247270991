import React from "react";
import { useNavigate } from "react-router-dom";

const ProcessMain = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/process_create_and_maintenance/summary");
  }, []);

  return <React.Fragment />;
};

export default ProcessMain;
