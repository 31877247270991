import { Controllers, ButtonSubmitController } from "radium-omnibus";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbMain from "../../components/breadcrumb/BreadCrumb.Main";
import {
  FieldSelectIsSearchAble,
  FieldSelectMultipleCheckBox,
} from "../../components/fields/Field.Select";
import {
  FunctionErrorCustoms,
  FunctionErrorCustomsSpaceBar,
} from "../../components/fields/functions/Field.Error";
import ModalMain from "../../components/modal/Modal.Main";
import TabMain from "../../components/tab/Tab.Main";
import WarningMain from "../../components/warning/Warning.Main";
import { SystemStoreLoading } from "../../systems/Systems.Loading";
import { SystemStoreUser } from "../../systems/Systems.StorageUser";
import {
  CONSTANT_PAGE_PROCESS,
  TAB_ARRAY,
  TYPE_APPROVED,
  TYPE_COMPLETED,
  TYPE_NEW,
  TYPE_REJECTED,
  TYPE_UPDATED,
} from "../../utils/useConstants";
import {
  FunctionCheckIsSuperAdmin,
  FunctionFormatDate,
} from "../../utils/useFunctions";
import { ICONS_WARNING } from "../../utils/useIcons";
import {
  OPTIONS_APPROVER,
  OPTIONS_DIVISION_OWNER,
  OPTIONS_FREQUENCY,
  OPTIONS_IT_APPLICATION,
  OPTIONS_PROCESS_TYPE,
  OPTIONS_PRODUCT,
  OPTIONS_SUB_PROCESS,
  OPTION_IN_AND_OUT,
} from "../../utils/useOptions";
import {
  STYLE_BODY_MODAL,
  STYLE_INPUT,
  STYLE_INPUT_DISABLE,
  STYLE_INPUT_ERROR,
  STYLE_INPUT_LABEL,
  STYLE_INPUT_TEXT_AREA,
  STYLE_PROCESS_CONTROLLER_GRAY,
  STYLE_PROCESS_CONTROLLER_VIOLET,
  STYLE_REMARK_CORE_CONTROLLER,
  STYLE_TEXT_MODAL_LABEL,
  STYLE_TEXT_MODAL_VALUE,
  STYLE_TITLE_CONTROLLER,
} from "../../utils/useStyle";
import ProcessFirstLoadSession from "./functions/Process.FirstLaodSession";
import ProcessLoadData from "./functions/Process.LoadData";
import ProcessOnClickReject from "./functions/Process.OnClickReject";
import ProcessOnSubmit from "./functions/Process.OnSubmit";
import HeaderRejected from "./render/Process.HeaderRejected";

// const ProcessUseEffectReciveData = ({ props }) => {
//   React.useEffect(() => {
//     if (props.received) {
//       props.setValue(props.received);
//     }
//     return () => {};
//   }, [props.received]);

//   return <React.Fragment />;
// };

const ProcessProcessControllerMemo = React.memo(
  ({
    initData: loadData,
    isDisabled,
    hasError,
    setForm,
    setOpenConfirm,
    user,
    isMyRequest,
  }) => {
    const optionsIO = OPTION_IN_AND_OUT();
    const optionsFrequency = OPTIONS_FREQUENCY();
    return (
      <React.Fragment>
        <Controllers
          loadData={loadData}
          classNameSuperCore={STYLE_PROCESS_CONTROLLER_VIOLET}
          showError={false}
          showErrorAlert={false}
          fields={[
            {
              type: "none",
              classNameCore: STYLE_TITLE_CONTROLLER,
              render: () => {
                return <div>{CONSTANT_PAGE_PROCESS.HEADER}</div>;
              },
            },
            {
              type: "text",
              name: "process_id",
              disabled: true,
              classNameCore: "hidden",
            },
            {
              type: "text",
              name: "process_name",
              classNameLabel: STYLE_INPUT_LABEL + " text-scbPrimary",
              label: CONSTANT_PAGE_PROCESS.LABEL_MAIN_PROCESS_NAME,
              classNameCore: "ml-24 col-span-2 ",
              disabled: isDisabled,
              errorCustoms: FunctionErrorCustomsSpaceBar,
              classNameInput: (props) => {
                if (isDisabled) {
                  return STYLE_INPUT_DISABLE;
                } else if (props?.thisValue?.error && hasError) {
                  return STYLE_INPUT_ERROR;
                } else {
                  return STYLE_INPUT;
                }
              },
              required: true,
            },
            {
              type: "customs",
              classNameLabel: STYLE_INPUT_LABEL,
              label: CONSTANT_PAGE_PROCESS.LABEL_PROCESS_CATEGORY,
              name: "process_cat",
              // emitter: "sub_process_type",
              fieldCustoms: (props) => {
                const options = OPTIONS_PROCESS_TYPE();
                return (
                  <FieldSelectIsSearchAble
                    hasError={hasError}
                    options={options}
                    props={props}
                    isDisabled={isDisabled}
                    // useReference={true}
                    // referenceName="process_id"
                  />
                );
              },
              required: true,
            },
            {
              type: "text",
              classNameLabel: STYLE_INPUT_LABEL + " text-scbPrimary",
              label: CONSTANT_PAGE_PROCESS.LABEL_SUB_PROCESS_NAME,
              name: "sub_process_name",
              classNameCore: "ml-24 col-span-2",
              disabled: isDisabled,
              errorCustoms: FunctionErrorCustomsSpaceBar,
              classNameInput: (props) => {
                if (isDisabled) {
                  return STYLE_INPUT_DISABLE;
                } else if (props?.thisValue?.error && hasError) {
                  return STYLE_INPUT_ERROR;
                } else {
                  return STYLE_INPUT;
                }
              },
              required: true,
            },
            {
              type: "customs",
              classNameLabel: STYLE_INPUT_LABEL,
              label: CONSTANT_PAGE_PROCESS.LABEL_SUB_PROCESS_TYPE,
              name: "sub_process_type",
              fieldCustoms: (props) => {
                const options = OPTIONS_SUB_PROCESS();
                return (
                  <FieldSelectIsSearchAble
                    hasError={hasError}
                    options={options}
                    props={props}
                    isDisabled={isDisabled}
                  />
                );
              },

              required: true,
            },
            {
              classNameLabel: STYLE_INPUT_LABEL,
              type: "textarea",
              label: CONSTANT_PAGE_PROCESS.LABEL_REMARK,
              errorCustoms: FunctionErrorCustoms,
              name: "sub_process_remark",
              classNameCore: STYLE_REMARK_CORE_CONTROLLER,
              disabled: isDisabled,
              classNameInput: (props) => {
                if (isDisabled) {
                  return STYLE_INPUT_DISABLE;
                } else if (props?.thisValue?.error && hasError) {
                  return STYLE_INPUT_ERROR;
                } else {
                  return STYLE_INPUT;
                }
              },
            },
          ]}
          onSubmit={(data) => {
            setForm((prev) => ({ ...prev, ...data }));
          }}
          classNameSubmit="hidden"
        />
        <Controllers
          loadData={loadData}
          showError={false}
          showErrorAlert={false}
          classNameSuperCore={STYLE_PROCESS_CONTROLLER_GRAY}
          fields={[
            {
              type: "none",
              classNameCore: STYLE_TITLE_CONTROLLER,
              render: () => {
                return <div>{CONSTANT_PAGE_PROCESS.IT_N_PRODUCT}</div>;
              },
            },
            {
              type: "customs",
              name: "it_apps",
              errorCustoms: ({ value }) => {
                if (value?.length === 0) {
                  return "Please select at least 1 IT Application.";
                } else {
                  return "";
                }
              },

              fieldCustoms: (props) => {
                let newProps = { ...props };
                newProps.options = OPTIONS_IT_APPLICATION();
                newProps.required = true;
                newProps.isMultiple = true;
                newProps.isSearchable = true;
                newProps.showRadio = true;
                newProps.label = CONSTANT_PAGE_PROCESS.LABEL_IT_APPLICATION;
                newProps.disabled = isDisabled;
                newProps.classNameLabel = STYLE_INPUT_LABEL;

                return (
                  <div className="ml-24">
                    <FieldSelectMultipleCheckBox
                      props={newProps}
                      hasError={hasError}
                    />
                  </div>
                );
              },
            },
            {
              type: "textarea",
              classNameLabel: STYLE_INPUT_LABEL,
              label: CONSTANT_PAGE_PROCESS.LABEL_END_USER_COMPUTING,
              errorCustoms: FunctionErrorCustomsSpaceBar,
              name: "end_user_comp",
              autoScale: true,
              disabled: isDisabled,
              classNameInput: (props) => {
                if (isDisabled) {
                  return STYLE_INPUT_DISABLE;
                } else if (props?.thisValue?.error && hasError) {
                  return STYLE_INPUT_ERROR;
                } else {
                  return STYLE_INPUT;
                }
              },
            },
            {
              type: "customs",
              name: "products",
              errorCustoms: ({ value }) => {
                if (value?.length === 0) {
                  return "Please select at least 1 product";
                } else {
                  return "";
                }
              },
              classNameLabel: STYLE_INPUT_LABEL,
              fieldCustoms: (props) => {
                let newProps = { ...props };

                newProps.options = OPTIONS_PRODUCT();
                newProps.required = true;
                newProps.isMultiple = true;
                newProps.isSearchable = true;
                newProps.showRadio = true;
                newProps.label = CONSTANT_PAGE_PROCESS.LABEL_PRODUCT;
                newProps.disabled = isDisabled;
                newProps.classNameLabel = STYLE_INPUT_LABEL;

                return (
                  <FieldSelectMultipleCheckBox
                    props={newProps}
                    hasError={hasError}
                  />
                );
              },
            },
          ]}
          onSubmit={(data) => {
            setForm((prev) => ({ ...prev, ...data }));
          }}
          classNameSubmit="hidden"
        />
        <Controllers
          loadData={loadData}
          classNameSuperCore={`grid grid-cols-2  gap-6 pl-5 pr-5 pb-7 pt-2  bg-scbSecondary text-30px`}
          showError={false}
          showErrorAlert={false}
          fields={[
            {
              type: "none",
              classNameCore: "col-span-2 text-32px text-lightBlack font-bold",
              render: () => {
                return <div>{CONSTANT_PAGE_PROCESS.PROCESS_OWNER}</div>;
              },
            },
            {
              type: "customs",
              classNameLabel: STYLE_INPUT_LABEL,
              label: CONSTANT_PAGE_PROCESS.LABEL_DIVISION_OWNER,
              name: "division_owner",
              classNameCore: "ml-24 ",
              initalValue: user?.org_id,
              emitter: "approver_name",
              required: true,
              fieldCustoms: (props) => {
                const options = OPTIONS_DIVISION_OWNER(loadData, isMyRequest);
                return (
                  <FieldSelectIsSearchAble
                    hasError={hasError}
                    isGroup={true}
                    props={props}
                    options={options}
                    isDisabled={isDisabled}
                  />
                );
              },
            },
            {
              type: "text",
              label: CONSTANT_PAGE_PROCESS.LABEL_SUB_DIVISION,
              name: "sub_division",
              classNameLabel: STYLE_INPUT_LABEL,
              disabled: isDisabled,
              errorCustoms: FunctionErrorCustomsSpaceBar,
              classNameInput: (props) => {
                if (isDisabled) {
                  return STYLE_INPUT_DISABLE;
                } else if (props?.thisValue?.error && hasError) {
                  return STYLE_INPUT_ERROR;
                } else {
                  return STYLE_INPUT;
                }
              },
            },
            {
              name: "approver_id",
              type: "text",
              classNameCore: "hidden",
            },
            {
              emitter: "division_owner",
              recipient: true,
              type: "customs",
              classNameLabel: STYLE_INPUT_LABEL,
              label: CONSTANT_PAGE_PROCESS.LABEL_APPROVER,
              name: "approver_name",
              classNameCore: "ml-24 ",
              fieldCustoms: (props) => {
                const options = OPTIONS_APPROVER(
                  props?.received?.value ||
                    loadData?.division_owner ||
                    user?.org_id,
                  loadData,
                  isMyRequest
                );

                return (
                  <FieldSelectIsSearchAble
                    hasError={hasError}
                    props={props}
                    isDisabled={isDisabled}
                    options={options}
                    useReference={true}
                    referenceName="emp_id"
                  />
                );
              },
              required: true,
            },
          ]}
          onSubmit={(data) => {
            setForm((prev) => ({ ...prev, ...data }));
          }}
          classNameSubmit="hidden"
        />
        <Controllers
          loadData={loadData}
          classNameSuperCore={STYLE_PROCESS_CONTROLLER_GRAY}
          showError={false}
          showErrorAlert={false}
          fields={[
            {
              type: "none",
              classNameCore: STYLE_TITLE_CONTROLLER,
              render: () => {
                return <div>{CONSTANT_PAGE_PROCESS.UPSTREAM}</div>;
              },
            },
            {
              type: "text",
              label: CONSTANT_PAGE_PROCESS.LABEL_UPSTEAM,
              name: "upstream",
              errorCustoms: FunctionErrorCustomsSpaceBar,
              classNameLabel: STYLE_INPUT_LABEL,
              classNameCore: "ml-24 ",
              disabled: isDisabled,
              classNameInput: (props) => {
                if (isDisabled) {
                  return STYLE_INPUT_DISABLE;
                } else if (props?.thisValue?.error && hasError) {
                  return STYLE_INPUT_ERROR;
                } else {
                  return STYLE_INPUT;
                }
              },
              required: true,
            },

            {
              type: "customs",
              label: CONSTANT_PAGE_PROCESS.LABEL_INPUT_TYPE,
              name: "up_input_type",
              classNameLabel: STYLE_INPUT_LABEL,
              fieldCustoms: (props) => {
                return (
                  <FieldSelectIsSearchAble
                    hasError={hasError}
                    options={optionsIO}
                    props={props}
                    isDisabled={isDisabled}
                  />
                );
              },
              required: true,
            },

            {
              type: "customs",
              label: CONSTANT_PAGE_PROCESS.LABEL_FREQUENCY,
              name: "up_freq",
              classNameLabel: STYLE_INPUT_LABEL,
              fieldCustoms: (props) => {
                return (
                  <FieldSelectIsSearchAble
                    hasError={hasError}
                    options={optionsFrequency}
                    props={props}
                    isDisabled={isDisabled}
                  />
                );
              },
              required: true,
            },
            {
              type: "textarea",
              classNameLabel: STYLE_INPUT_LABEL,
              label: CONSTANT_PAGE_PROCESS.LABEL_REMARK,
              errorCustoms: FunctionErrorCustoms,
              name: "up_remark",
              classNameCore: STYLE_REMARK_CORE_CONTROLLER,
              disabled: isDisabled,
              classNameInput: (props) => {
                if (isDisabled) {
                  return STYLE_INPUT_DISABLE;
                } else if (props?.thisValue?.error && hasError) {
                  return STYLE_INPUT_ERROR;
                } else {
                  return STYLE_INPUT_TEXT_AREA;
                }
              },
            },
          ]}
          onSubmit={(data) => {
            setForm((prev) => ({ ...prev, ...data }));
          }}
          classNameSubmit="hidden"
        />
        <Controllers
          loadData={loadData}
          classNameSuperCore={STYLE_PROCESS_CONTROLLER_VIOLET}
          showError={false}
          showErrorAlert={false}
          fields={[
            {
              type: "none",
              classNameCore: STYLE_TITLE_CONTROLLER,
              render: () => {
                return <div>{CONSTANT_PAGE_PROCESS.DOWNSTREAM}</div>;
              },
            },
            {
              type: "text",
              label: CONSTANT_PAGE_PROCESS.LABEL_DOWNSTREAM,
              name: "down_stream",
              classNameLabel: STYLE_INPUT_LABEL,
              classNameCore: "ml-24 ",
              disabled: isDisabled,
              errorCustoms: FunctionErrorCustomsSpaceBar,
              classNameInput: (props) => {
                if (isDisabled) {
                  return STYLE_INPUT_DISABLE;
                } else if (props?.thisValue?.error && hasError) {
                  return STYLE_INPUT_ERROR;
                } else {
                  return STYLE_INPUT;
                }
              },
              required: true,
            },
            {
              type: "customs",
              label: CONSTANT_PAGE_PROCESS.LABEL_OUTPUT_TYPE,
              name: "dn_input_type",
              classNameLabel: STYLE_INPUT_LABEL,
              fieldCustoms: (props) => {
                return (
                  <FieldSelectIsSearchAble
                    hasError={hasError}
                    options={optionsIO}
                    props={props}
                    isDisabled={isDisabled}
                  />
                );
              },
              required: true,
            },
            {
              type: "customs",
              classNameLabel: STYLE_INPUT_LABEL,
              label: CONSTANT_PAGE_PROCESS.LABEL_FREQUENCY,
              name: "dn_freq",
              fieldCustoms: (props) => {
                return (
                  <FieldSelectIsSearchAble
                    hasError={hasError}
                    options={optionsFrequency}
                    props={props}
                    isDisabled={isDisabled}
                  />
                );
              },
              required: true,
            },
            {
              type: "textarea",
              label: CONSTANT_PAGE_PROCESS.LABEL_REMARK,
              errorCustoms: FunctionErrorCustoms,
              name: "dn_remark",
              classNameLabel: STYLE_INPUT_LABEL,
              classNameCore: STYLE_REMARK_CORE_CONTROLLER,
              disabled: isDisabled,
              classNameInput: (props) => {
                if (isDisabled) {
                  return STYLE_INPUT_DISABLE;
                } else if (props?.thisValue?.error && hasError) {
                  return STYLE_INPUT_ERROR;
                } else {
                  return STYLE_INPUT_TEXT_AREA;
                }
              },
            },
          ]}
          onSubmit={(data) => {
            setForm((prev) => {
              console.log("FORM DATA ", { ...prev, ...data });
              return { ...prev, ...data };
            });

            setOpenConfirm(true);
          }}
          classNameSubmit="hidden"
        />
      </React.Fragment>
    );
  }
);

const ProcessProcess = () => {
  const [form, setForm] = React.useState({});
  const [initData, setInitData] = React.useState({});
  const [hasLoad, sethasLoad] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openReject, setOpenReject] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState("");
  const [tab, setTab] = React.useState("PROCESS REGISTRATION");
  const [openClose, setOpenClose] = React.useState(false);
  const [originalData, setOriginalData] = React.useState({});

  const setLoading = SystemStoreLoading((state) => state.setLoading);
  const loading = SystemStoreLoading((state) => state.loading);

  const { id } = useParams();
  const user = SystemStoreUser((state) => state.user);

  const navigate = useNavigate();

  const isCreate = id === "create";

  const isStatusNew = initData?.tran_status === TYPE_NEW;
  const isStatusRejcted = initData?.tran_status === TYPE_REJECTED;
  const isStatusApproved = initData?.tran_status === TYPE_APPROVED;
  const isStatusCompleted = initData?.tran_status === TYPE_COMPLETED;
  const isStatusUpdate = initData?.tran_status === TYPE_UPDATED;

  const isMyRequest = initData?.request_id === user?.user_id;
  const isMyApprover = initData?.approver_id === user?.user_id;

  const isSuperAdmin = FunctionCheckIsSuperAdmin(user);

  const isDisabled =
    isStatusApproved ||
    isStatusCompleted ||
    // isStatusNew ||
    // isStatusUpdate ||
    (!isCreate && !isMyRequest);

  const isMainProcessFromLoadData =
    initData?.sub_process_id?.split("-")[1] === "01";

  const showProcessId = isCreate
    ? initData?.sub_process_id
      ? initData?.sub_process_id
      : initData?.process_id || CONSTANT_PAGE_PROCESS.TEXT_GENERATE
    : isMainProcessFromLoadData
    ? initData?.process_id
    : initData?.sub_process_id;

  const canClickSubmit =
    isCreate || // CREATE
    (isStatusRejcted && isMyRequest) || // UPDATE AFTER REJECTED
    ((isStatusNew || isStatusUpdate) && (isMyRequest || isMyApprover)) || // EDIT AND APPROVE
    (isStatusApproved && isSuperAdmin) ||
    (isStatusUpdate && isMyApprover);

  const canClickReject =
    (isStatusNew && isMyApprover) ||
    (isStatusUpdate && isMyApprover) ||
    (isStatusApproved && isMyApprover) ||
    (isStatusApproved && isSuperAdmin) ||
    (isStatusCompleted && isSuperAdmin);

  const canClickCancel =
    isCreate ||
    ((isStatusRejcted || isStatusUpdate || isStatusNew) && isMyRequest);

  const canClickClose =
    !isCreate &&
    !((isStatusRejcted || isStatusUpdate || isStatusNew) && isMyRequest);

  React.useEffect(() => {
    let mounted = true;

    if (isCreate) {
      ProcessFirstLoadSession({ setInitData, sethasLoad, hasLoad });
    } else {
      ProcessLoadData(
        setInitData,
        id,
        setTab,
        setLoading,
        isSuperAdmin,
        setOriginalData,
        user
      );
    }

    return () => {
      mounted = false;
    };
  }, [id]);

  React.useEffect(() => {
    if (window !== undefined && window !== null) {
      if (window) {
        window.onpopstate = () => {
          sessionStorage.setItem("check-status", "non-reset");
        };
      }
    }
  }, []);

  if (initData?.tran_status) {
    console.log("CHECK FIRST LOAD :", {
      status: initData?.tran_status,
      isMyRequest,
      isCreate,
      canClickSubmit,
      canClickReject,
      isMyApprover,
      initData,
      user,
      showProcessId,
    });
  }

  return (
    <div className="px-90px">
      <div className="flex justify-between">
        <BreadCrumbMain
          disable={2}
          onClick={({ event }) => {
            event.stopPropagation();
            setOpenClose(true);
          }}
        />
        <TabMain actions={TAB_ARRAY} value={tab} setValue={setTab} />
      </div>
      <WarningMain data={initData} isMyApprover={isMyApprover} />
      {initData?.process_id && (
        <div className="text-32px text-lightBlack font-bold">
          {CONSTANT_PAGE_PROCESS.LABEL_PROCESS_ID} {showProcessId}
        </div>
      )}
      {isStatusRejcted && <HeaderRejected data={initData} />}
      <ProcessProcessControllerMemo
        initData={initData}
        isDisabled={isDisabled}
        hasError={hasError}
        setForm={setForm}
        setOpenConfirm={setOpenConfirm}
        user={user}
        originalData={originalData}
        isMyRequest={isMyRequest}
      />
      <div className="flex justify-between space-x-4 mb-8">
        <div className="flex flex-col">
          {id &&
          initData?.request_id &&
          initData?.request_name &&
          initData?.request_datetime ? (
            <div className="my-auto text-[30px] ">
              {CONSTANT_PAGE_PROCESS.REGISTER_BY.replace(
                "[register_id]",
                initData?.request_id
              )
                .replace("[register_by]", initData?.request_name)
                .replace(
                  "[register_date]",
                  FunctionFormatDate(initData?.request_datetime)
                )}
            </div>
          ) : (
            <div></div>
          )}
          {id &&
          initData?.approve_name &&
          initData?.approve_id &&
          initData?.approve_datetime &&
          (isStatusApproved || isStatusCompleted) ? (
            <div className="my-auto text-[30px] ">
              {CONSTANT_PAGE_PROCESS.APPROVE_BY.replace(
                "[approve_id]",
                initData?.approver_id
              )
                .replace("[approve_by]", initData?.approve_name)
                .replace(
                  "[approve_date]",
                  FunctionFormatDate(initData?.approve_datetime)
                )}
            </div>
          ) : (
            <div></div>
          )}
          {id &&
          initData?.complete_name &&
          initData?.complete_id &&
          initData?.complete_datetime &&
          isStatusCompleted ? (
            <div className="my-auto text-[30px] ">
              {CONSTANT_PAGE_PROCESS.COMPLEATED_BY.replace(
                "[compleated_id]",
                initData?.complete_id
              )
                .replace("[compleated_by]", initData?.complete_name)
                .replace(
                  "[compleated_date]",
                  FunctionFormatDate(initData?.complete_datetime)
                )}
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className="flex justify-end space-x-8">
          {canClickClose && (
            <button
              onClick={() => {
                // setOpenClose(true);
                navigate("/process_create_and_maintenance/summary");
              }}
              className="bg-scbPrimary shadow-xl  px-10 py-2 rounded-md text-white font-bold text-28px cursor-pointer  h-[58px]"
            >
              {CONSTANT_PAGE_PROCESS.CLOSE}
            </button>
          )}

          {canClickReject && (
            <div
              onClick={() => {
                setOpenReject(true);
              }}
              className="text-scbPrimary font-bold bg-white round-md shadow-xl  px-10 py-2 text-28px cursor-pointer  h-[58px]"
            >
              {CONSTANT_PAGE_PROCESS.REJECT}
            </div>
          )}

          {canClickCancel && (
            <button
              onClick={() => {
                setOpenClose(true);
              }}
              className="bg-white font-bold shadow-xl px-10 py-2 rounded-md text-scbPrimary text-28px cursor-pointer  h-[58px]"
            >
              {CONSTANT_PAGE_PROCESS.CANCEL}
            </button>
          )}

          {canClickSubmit && (
            <ButtonSubmitController
              classNameSubmit=" bg-scbPrimary px-10 py-2 font-bold rounded-md shadow-2xl text-white text-28px cursor-pointer  h-[58px]"
              labelSubmit={
                isStatusNew || isStatusApproved || isStatusUpdate
                  ? isMyRequest &&
                    !(isMyRequest && isSuperAdmin && isStatusApproved)
                    ? CONSTANT_PAGE_PROCESS.LABEL_ON_SUBMIT
                    : CONSTANT_PAGE_PROCESS.LABEL_ON_APPROVE
                  : CONSTANT_PAGE_PROCESS.LABEL_ON_SUBMIT
              }
            />
          )}
        </div>
      </div>
      {openConfirm && (
        <ModalMain
          maxWidth="max-w-4xl"
          labelSubmit={CONSTANT_PAGE_PROCESS.LABEL_MODAL_SUBMIT}
          labelCancel={CONSTANT_PAGE_PROCESS.LABEL_MODAL_CANCEL}
          title={CONSTANT_PAGE_PROCESS.TITLE_MODAL_SUBMIT}
          open={openConfirm}
          setOpen={setOpenConfirm}
          body={
            <div className={STYLE_BODY_MODAL}>
              <div className={STYLE_TEXT_MODAL_LABEL}>
                {CONSTANT_PAGE_PROCESS.LABEL_PROCESS_ID}
              </div>
              <div
                className={`${
                  showProcessId === CONSTANT_PAGE_PROCESS.TEXT_GENERATE
                    ? " italic . "
                    : "."
                } ${STYLE_TEXT_MODAL_VALUE} `}
              >
                {showProcessId}
              </div>

              <div className={STYLE_TEXT_MODAL_LABEL}>
                {CONSTANT_PAGE_PROCESS.HEADER_MAIN_PROCESS_NAME}
              </div>
              <div className={STYLE_TEXT_MODAL_VALUE}>
                {typeof form?.process_name === "string"
                  ? form?.process_name
                  : "-"}
              </div>
              <div className={STYLE_TEXT_MODAL_LABEL}>
                {CONSTANT_PAGE_PROCESS.HEADER_SUB_PROCESS_NAME}
              </div>
              <div className={STYLE_TEXT_MODAL_VALUE}>
                {typeof form?.sub_process_name === "string"
                  ? form?.sub_process_name
                  : "-"}
              </div>
            </div>
          }
          onClickSubmit={() => {
            ProcessOnSubmit(
              id,
              form,
              initData?.tran_status,
              setOpenError,
              setHasError,
              setOpenConfirm,
              setOpenSuccess,
              setErrorMessages,
              setLoading,
              setInitData,
              originalData,
              isMyRequest,
              isMyApprover,
              isSuperAdmin,
              loading
            );
          }}
        />
      )}
      {openError && (
        <ModalMain
          open={openError}
          setOpen={setOpenError}
          onClose={() => {
            setErrorMessages("");
            setHasError(true);
            setOpenError(false);
          }}
          maxWidth="max-w-2xl"
          styleModal={{
            height: "150px",
            width: "200",
          }}
          body={
            <div className="text-center text-32px font-bold">
              <img src="/icon/x.png" className="w-16 h-16 mx-auto"></img>
              <div className="text-32px mt-2">
                {CONSTANT_PAGE_PROCESS.HEADER_MODAL_ERROR}
              </div>
              <div className="py-3 mb-10 text-32px font-bold">
                {errorMessages
                  ? errorMessages
                  : CONSTANT_PAGE_PROCESS.MESSAGE_MODAL_ERROR}
              </div>
              <div
                className="bg-scbPrimary shadow-xl font-bold rounded-md cursor-pointer w-130px mx-auto mt-6 text-24px py-1 text-white"
                onClick={() => {
                  setHasError(true);
                  setOpenError(false);
                }}
              >
                CLOSE
              </div>
            </div>
          }
          footer={false}
        />
      )}
      {openSuccess && (
        <ModalMain
          open={openSuccess}
          setOpen={setOpenSuccess}
          maxWidth="max-w-2xl"
          onClose={() => {
            navigate("/process_create_and_maintenance");
          }}
          body={
            <div className="text-center text-32px font-bold">
              <img src="/icon/accept.png" className="w-16 h-16 mx-auto"></img>
              <div className="text-32px mt-2">
                {CONSTANT_PAGE_PROCESS.COMPLEATED}
              </div>
              <div className="y-3 mb-10">
                {CONSTANT_PAGE_PROCESS.LABEL_PROCESS_ID} {showProcessId}
              </div>
              <div
                className="bg-scbPrimary rounded-xl cursor-pointer w-130px mx-auto mt-6 text-24px py-1 text-white"
                onClick={() => {
                  // setOpenSuccess(false);
                  navigate("/process_create_and_maintenance");
                }}
              >
                {CONSTANT_PAGE_PROCESS.OK}
              </div>
            </div>
          }
          footer={false}
        />
      )}
      {openReject && (
        <ModalMain
          labelCancel={CONSTANT_PAGE_PROCESS.LABEL_MODAL_CANCEL}
          labelSubmit={CONSTANT_PAGE_PROCESS.LABEL_MODAL_SUBMIT}
          title={CONSTANT_PAGE_PROCESS.TITLE_MODAL_REJECT}
          open={openReject}
          setOpen={setOpenReject}
          onClose={() => {
            setErrorMessages("");
          }}
          body={
            <div className={STYLE_BODY_MODAL}>
              <div className={STYLE_TEXT_MODAL_LABEL}>
                {CONSTANT_PAGE_PROCESS.LABEL_PROCESS_ID}
              </div>
              <div className={STYLE_TEXT_MODAL_VALUE}>{showProcessId}</div>
              <div className={STYLE_TEXT_MODAL_LABEL}>
                {CONSTANT_PAGE_PROCESS.HEADER_MAIN_PROCESS_NAME}
              </div>
              <div className={STYLE_TEXT_MODAL_VALUE}>
                {typeof initData?.process_name === "string"
                  ? initData?.process_name
                  : "-"}
              </div>
              <div className={STYLE_TEXT_MODAL_LABEL}>
                {CONSTANT_PAGE_PROCESS.HEADER_SUB_PROCESS_NAME}
              </div>
              <div className={STYLE_TEXT_MODAL_VALUE}>
                {typeof initData?.sub_process_name === "string"
                  ? initData?.sub_process_name
                  : "-"}
              </div>
              <Controllers
                classNameSuperCore="col-span-3"
                fields={[
                  {
                    classNameLabel: STYLE_TEXT_MODAL_LABEL,
                    classNameWrapper: "grid grid-cols-3",
                    classNameSubLabel: "hidden",
                    classNameInput: STYLE_INPUT + " col-span-2 ",
                    type: "textarea",
                    label: CONSTANT_PAGE_PROCESS.LABEL_MODAL_REASON,
                    name: "reason-reject",
                  },
                ]}
              />
            </div>
          }
          onClickSubmit={() => {
            const resonReject =
              document.getElementById("reason-reject").innerHTML;

            ProcessOnClickReject({
              tran_id: id,
              resonReject: resonReject,
              approver: `${user?.user_id}-${user?.user_name}`,
              setErrorMessages,
              setOpenError,
              setLoading,
              navigate,
              setOpenSuccess,
              setOpenReject,
            });
          }}
        />
      )}
      {openClose && (
        <ModalMain
          labelCancel={CONSTANT_PAGE_PROCESS.LABEL_MODAL_CANCEL}
          labelSubmit={CONSTANT_PAGE_PROCESS.LABEL_MODAL_SUBMIT}
          open={openClose}
          maxWidth="max-w-2xl"
          setOpen={setOpenClose}
          onClose={() => {
            setOpenClose(false);
          }}
          body={
            <div className="text-center text-32px font-bold">
              <ICONS_WARNING />
              <div className="text-32px mt-6">
                {CONSTANT_PAGE_PROCESS.HEADER_MODAL_CLOSE}
              </div>
            </div>
          }
          onClickSubmit={() => {
            navigate("/process_create_and_maintenance");
          }}
        />
      )}
    </div>
  );
};
export default ProcessProcess;

//BUTTON COMPLETE 7214 สามารถกด COMPLETE ได้แค่ Role เดียว
