import { Controllers } from "radium-omnibus";
import React from "react";
import { useNavigate } from "react-router-dom";
import ModalMain from "../../components/modal/Modal.Main";
import { SystemStoreLoading } from "../../systems/Systems.Loading";
import { SystemStoreUser } from "../../systems/Systems.StorageUser";
import { version } from "../../utils/useConfig";
import { CONSTANT_PAGE_LOGIN } from "../../utils/useConstants";
import { STYLE_INPUT, STYLE_INPUT_LOGIN } from "../../utils/useStyle";
import LoginOnSubmit from "./functions/Login.OnSubmit";

const LoginMain = () => {
  const [openError, setOpenError] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState("");

  const navigate = useNavigate();
  const user = SystemStoreUser((state) => state.user);
  const setUser = SystemStoreUser((state) => state.setUser);
  const setLoading = SystemStoreLoading((state) => state.setLoading);
  React.useEffect(() => {
    if (user) {
      navigate("/");
    }
    return () => {};
  }, [user]);

  return (
    <div
      style={{
        backgroundImage: "url(/images/bglogin.png)",
      }}
      className="bg-white h-screen w-screen m-auto pt-12 bg-cover"
    >
      <div className="flex mx-auto justify-center">
        <img src="/logo.png" className="w-24 h-24 my-auto mr-4"></img>
        <div className="text-scbPrimary">
          <div className="text-70px font-semibold">
            {CONSTANT_PAGE_LOGIN.HEADER}
          </div>
          <div className="text-46px font-light">
            {CONSTANT_PAGE_LOGIN.SUB_HEADER}
          </div>
        </div>
      </div>
      <img className="mx-auto mt-12" src="images/hero.png" />
      <Controllers
        showError={false}
        showErrorAlert={false}
        showSymbol={false}
        classNameSuperCore="w-640px mx-auto mt-12 text-34px space-y-4"
        fields={[
          {
            name: "username",
            label: "",
            type: "text",
            required: true,
            placeholder: CONSTANT_PAGE_LOGIN.PLACEHOLDER_USERNAME,
            subLabel: "*",
            classNameSubLabel: "text-red absolute right-4 top-4 ",
            classNameWrapper: "relative",
            classNameInput: STYLE_INPUT_LOGIN,
            onKeyPress: ({ event, data }) => {
              if (event.key === "Enter") {
                LoginOnSubmit(
                  data,
                  setOpenError,
                  navigate,
                  setErrorMessages,
                  setUser,
                  setLoading
                );
              }
            },
          },
          {
            name: "password",
            placeholder: CONSTANT_PAGE_LOGIN.PLACEHOLDER_PASSWORD,
            label: "",
            type: "password",
            required: true,
            classNameSubLabel: "text-red absolute right-4 top-4 ",
            classNameWrapper: "relative",
            subLabel: "*",
            classNameInput: STYLE_INPUT_LOGIN,
            classNameIcon: "hidden",
            onKeyPress: ({ event, data }) => {
              if (event.key === "Enter") {
                LoginOnSubmit(
                  data,
                  setOpenError,
                  navigate,
                  setErrorMessages,
                  setUser,
                  setLoading
                );
              }
            },
          },
          {
            type: "submit",
            classNameSubmit:
              "bg-scbPrimary hover:bg-violet-800 text-white py-2 px-4 rounded text-center w-full mt-4",
            onSubmit: (data) => {
              LoginOnSubmit(
                data,
                setOpenError,
                navigate,
                setErrorMessages,
                setUser,
                setLoading
              );
            },
            labelSubmit: "SIGN IN",
          },
        ]}
      />
      <div className="absolute right-10 bottom-10 text-30px text-scbPrimary font-bold">
        ORDP V {version}
      </div>

      {openError && (
        <ModalMain
          open={openError}
          setOpen={setOpenError}
          onClose={() => {
            setOpenError(false);
            setErrorMessages("");
          }}
          maxWidth="max-w-2xl"
          styleModal={{
            height: "150px",
            width: "200",
          }}
          body={
            <div className="text-center text-32px font-bold">
              <img src="/icon/x.png" className="w-16 h-16 mx-auto" />
              <div className="text-32px mt-2">
                {CONSTANT_PAGE_LOGIN.MODAL_HEADER}
              </div>
              <div className="py-3 mb-10">
                {errorMessages
                  ? errorMessages
                  : CONSTANT_PAGE_LOGIN.MODAL_ERROR_MESSAGE}
              </div>
              <div
                className="bg-scbPrimary rounded-xl cursor-pointer w-130px mx-auto mt-6 text-24px h-12 text-white"
                onClick={() => {
                  setOpenError(false);
                }}
              >
                {CONSTANT_PAGE_LOGIN.CLOSE_BUTTON}
              </div>
            </div>
          }
          footer={false}
        />
      )}
    </div>
  );
};

export default LoginMain;
