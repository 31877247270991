import api from "../../../utils/useApi";
import { FunctionFormatDateYYYYMMDD } from "../../../utils/useFunctions";

const ProcessOnClickReject = async ({
  tran_id,
  resonReject,
  approver,
  setErrorMessages,
  setOpenError,
  setLoading,
  navigate,
  setOpenSuccess,
  setOpenReject,
}) => {
  setLoading(true);

  try {
    await api.api_process_reject({
      id: tran_id,
      // data: {
      //   reject_reasons: [
      //     {
      //       date: FunctionFormatDateYYYYMMDD(new Date()),
      //       description: resonReject,
      //       approver: approver,
      //     },
      //   ],
      // },
      data: {
        description: resonReject,
      },
    });
    setOpenReject(false);
    setOpenSuccess(true);
    // navigate("/process_create_and_maintenance");
  } catch (error) {
    console.error("ERROR ProcessOnClickReject", error);
    const thisError = error?.response?.data?.status?.description;
    setErrorMessages(thisError);
    setOpenError(true);
  } finally {
    setLoading(false);
  }
};

export default ProcessOnClickReject;
