import { FunctionCloneDeep } from "radium-omnibus";

const ProcessSetPageForLoad = (setValue, value) => {
  let loadParams = FunctionCloneDeep(
    JSON.parse(sessionStorage.getItem("process_search"))
  );
  loadParams.page_index = Number(value);
  sessionStorage.setItem("process_search", JSON.stringify(loadParams));

  setValue(Number(value));
};

export default ProcessSetPageForLoad;
