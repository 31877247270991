import React from "react";
import { useLocation } from "react-router-dom";
import api from "../utils/useApi";
import create from "zustand";
import { persist } from "zustand/middleware";
import { sessionExpire } from "../utils/useConfig";
import { FunctionParseJwt } from "../utils/useFunctions";

export const SystemStoreUser = create(
  persist(
    (set, get) => ({
      user: null,
      setUser: (user) => set(() => ({ user })),
      clearUser: () => set(() => ({ user: null })),
    }),
    {
      name: "user",
      getStorage: () => sessionStorage,
    }
  )
);

export const FunctionGetTokenFromLocalStorage = () => {
  const access_token = sessionStorage.getItem("access_token");
  const expires_in = sessionStorage.getItem("expires_in");
  const refresh_token = sessionStorage.getItem("refresh_token");
  const refresh_expires_in = sessionStorage.getItem("refresh_expires_in");

  return {
    access_token,
    expires_in,
    refresh_token,
    refresh_expires_in,
    info: FunctionParseJwt(access_token),
  };
};

export const FunctionGetExpiredDate = (expires_in) => {
  return Date.now() + expires_in * 1000;
};

export const SystemSetTokenSessionStorage = ({ data }) => {
  sessionStorage.setItem("access_token", data.access_token);
  sessionStorage.setItem("expires_in", data.expires_in);
  sessionStorage.setItem("refresh_token", data.refresh_token);
  sessionStorage.setItem("refresh_expires_in", data.refresh_expires_in);
  sessionStorage.setItem("user_role", data.user_role);
  sessionStorage.setItem("expired_at", FunctionGetExpiredDate(data.expires_in));
  sessionStorage.setItem(
    "refresh_expired_at",
    FunctionGetExpiredDate(data.refresh_expires_in)
  );
  sessionStorage.setItem(
    "plan_refresh_token_expired_at",
    FunctionGetExpiredDate(data.expires_in) - 1000 * 60 * 2
  );
};

export const SystemsSetStorage = async ({ data, setUser }) => {
  SystemSetTokenSessionStorage({ data });
  const {
    data: { data: profile },
  } = await api.api_authen_profile();
  sessionStorage.setItem("user", JSON.stringify(profile));
  setUser(profile);
};

export const SystemLogout = async () => {
  try {
    const refresh_token = sessionStorage.getItem("refresh_token");
    if (refresh_token) {
      await api.api_authen_logout({ refresh_token });
    }
    sessionStorage.clear();
    window.location.reload();
  } catch (error) {
    sessionStorage.clear();
    window.location.reload();
  }
};

export const SystemCapture = async (
  updateActive = true,
  setStopRefreshToken
) => {
  const lastActive = sessionStorage.getItem("lastActive");
  const today = new Date().getTime();
  if (lastActive !== null && today - lastActive >= +sessionExpire * 1000) {
    setStopRefreshToken(true);
    await SystemLogout();
    alert("Session expired");
    return;
  }
  if (updateActive) return sessionStorage.setItem("lastActive", today);
};
