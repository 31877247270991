import React from "react";
import {
  SystemLogout,
  SystemStoreUser,
} from "../../../systems/Systems.StorageUser";
import {
  CONSTANT_MODAL_LOGOUT,
  DOM_NAME_NAVBAR_ROLE,
} from "../../../utils/useConstants";
import { ICON_ARROW_DOWN } from "../../../utils/useIcons";
import ModalMain from "../../modal/Modal.Main";
import { version } from "../../../utils/useConfig";
import { FunctionFormatDateWithTime } from "../../../utils/useFunctions";
import { useLocation } from "react-router-dom";
import Emitter from "../../../utils/useEmitter";

const ModalUserBody = () => {
  const [openPopup, setOpenPopup] = React.useState(false);
  const user = SystemStoreUser((state) => state.user);
  return (
    <React.Fragment>
      <div className="bg-lightGrey mt-5 bg-opacity-100 text-black text-28px py-3 px-5 text-right rounded-lg shadow-xl">
        <div className="text-34px">
          {String(user?.user_name)?.toLocaleUpperCase()}
        </div>
        <div>
          {user?.oc_code} - {`<`}
          {user?.org_type}
          {`>`}
        </div>
        <div>Last Login : {FunctionFormatDateWithTime(user?.last_login)}</div>
        <div>ORDP Version : {version}</div>
        <div className="border border-b-gray-400 my-4" />
        <button
          onClick={async (e) => {
            e.stopPropagation();
            setOpenPopup(true);
          }}
          className="text-30px text-scbPrimary flex justify-end w-full"
        >
          <img src="/icon/log-out.png" className="w-10 h-10 mr-4 mt-1"></img>
          LOG OUT
        </button>
      </div>
      {openPopup && (
        <ModalMain
          labelCancel={CONSTANT_MODAL_LOGOUT.LABEL_CANCEL_BUTTON}
          labelSubmit={CONSTANT_MODAL_LOGOUT.LABEL_SUBMIT_BUTTON}
          open={openPopup}
          setOpen={setOpenPopup}
          onClickSubmit={async () => {
            await SystemLogout();
          }}
          onClose={() => {
            setOpenPopup(false);
          }}
          maxWidth="max-w-2xl"
          styleModal={{
            height: "150px",
            width: "200",
          }}
          body={
            <div className="text-center text-32px font-bold">
              {CONSTANT_MODAL_LOGOUT.MESSAGE}
            </div>
          }
        />
      )}
    </React.Fragment>
  );
};

const NavbarModalUser = () => {
  const [open, setOpen] = React.useState(false);
  const user = SystemStoreUser((state) => state.user);
  const location = useLocation();
  // const [lisenner, setLisenner] = React.useState(null);
  // Create a new emitter instance

  // React.useEffect(() => {
  //   Emitter.on("tab", ({ value }) => {
  //     setLisenner(value);
  //   });
  // }, [setLisenner]);

  const RenderBottom = () => {
    if (location.pathname === "/") {
      return <div className="h-9"></div>;
    }
    //  else if (
    //   location.pathname.includes("/process_create_and_maintenance/process/")
    // ) {
    //   return (
    //     <div id={DOM_NAME_NAVBAR_ROLE} className="text-[22px] text-end mr-12 ">
    //       {lisenner}
    //     </div>
    //   );
    // }
    else {
      return <div className="text-[22px] text-end mr-12 ">{user?.role}</div>;
    }
    // {location.pathname === "/" ? (
    //     <div className="h-9"></div>
    //   ) : (
    //     <div className="text-[22px] text-end mr-12 ">{user?.role}</div>
    //   )}
  };

  return (
    <div className=" z-10 my-auto  text-white font-bold -space-y-1">
      <div className="text-38px mr-12 text-end ">{user?.user_name}</div>
      <div className=" flex justify-end text-32px">
        <div className="text-right">OC : {user?.oc_code}</div>
        <div className="">
          <ICON_ARROW_DOWN
            onClick={() => {
              setOpen(!open);
            }}
            className={` cursor-pointer `}
            // className={`${
            //   open ? " rotate-180 " : " rotate-0 "
            // } transition-all duration-300 cursor-pointer `}
          />

          {open && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
              className="bg-opacity-0 w-screen h-screen absolute top-0 left-0"
            >
              <div className={`absolute right-24 top-32`}>
                {open && <ModalUserBody />}
              </div>
            </div>
          )}
        </div>
      </div>
      <RenderBottom />
    </div>
  );
};
export default NavbarModalUser;
