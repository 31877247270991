import { TAB_ARRAY } from "../../../utils/useConstants";
import Emitter from "../../../utils/useEmitter";

const ProcessFirstLoadSession = ({ setInitData, sethasLoad, hasLoad }) => {
  if (!hasLoad) {
    sethasLoad(true);

    let process = sessionStorage.getItem("process");

    if (
      process !== null &&
      process !== undefined &&
      process !== "" &&
      process !== "undefined"
    ) {
      process = JSON.parse(process);

      process = {
        ...process,
        // process_cat: process?.process_cat,
        // division_owner: user?.org_id,
        // temp_process: {
        //   process_id: process?.process_id,
        //   process_name: process?.process_name,
        //   process_cat: process?.process_cat,
        // },
      };

      if (process.value) {
        delete process.value;
      }

      if (process.label) {
        delete process.label;
      }

      setInitData(process);
    } else {
      setInitData({});
    }
    Emitter.emit(`tab`, {
      value: TAB_ARRAY[0],
    });
  }
  sessionStorage.removeItem("process");
};
export default ProcessFirstLoadSession;
