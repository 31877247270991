import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbFunctionGeneratePath from "./functions/BreadCrumb.FunctionGeneratePath";
import React from "react";

const BreadCrumbMain = ({ disable, onClick = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-between my-30px border-b py-2  border-gray-300 w-full">
        <div className="flex">
          {BreadCrumbFunctionGeneratePath(location)?.map(
            (path, index, self) => {
              const canClick = index !== self.length - 1 && disable !== index;

              if (path?.name === "Create") {
                return <React.Fragment key={`keys-of-path-n-${index}`} />;
              } else {
                return (
                  <div
                    key={`keys-of-path-${index}`}
                    onClick={(e) => {
                      if (onClick) {
                        onClick({ event: e, path: path });
                      } else {
                        if (canClick) {
                          sessionStorage.setItem("check-status", "non-reset");
                          navigate(path.path);
                        } else {
                          return;
                        }
                      }
                    }}
                    className={`${
                      canClick
                        ? " cursor-pointer text-32px font-normal hover:text-scbPrimary mx-1"
                        : "text-32px font-normal mx-1"
                    } `}
                  >
                    {index <= self?.length && index !== 0 && " / "} {path.name}
                  </div>
                );
              }
            }
          )}
        </div>
      </div>
    </>
  );
};

export default BreadCrumbMain;
