export const DEFAULT_COUNT_ACTIVE_SEARCH = 2;
export const DEFAULT_PAGE = 1;
export const DEFAULT_RECORD_PER_PAGE = 10;

export const TYPE_REJECTED = "Rejected";
export const TYPE_NEW = "New";
export const TYPE_APPROVED = "Approved";
export const TYPE_COMPLETED = "Completed";
export const TYPE_REGISTER = "Register";
export const TYPE_UPDATED = "Updated";
export const TYPE_REJECTED_LABEL = "Rejected";
export const TYPE_NEW_LABEL = "New";
export const TYPE_APPROVED_LABEL = "Approved";
export const TYPE_COMPLETED_LABEL = "Completed";
export const TYPE_REGISTER_LABEL = "Register";
export const TYPE_UPDATED_LABEL = "Updated";
export const TYPE_NA_LABEL = "NA";
export const TYPE_NA_VALUE = "NA";

export const DOM_NAME_PROCESS_ID = "ref_process_id";
export const DOM_NAME_NAVBAR_ROLE = "ref_navbar_role";

export const TAB_ARRAY = [
  "PROCESS REGISTRATION",
  "BU APPROVAL",
  "OPRISK APPROVAL",
];

export const CONSTANT_PLACEHOLDER = "";

export const CONSTANT_PAGE_PROCESS = {
  PROCESS_ID: "PROCESS ID",
  LABEL_PROCESS_ID: "PROCESS ID :",
  HEADER: "PROCESS",
  IT_N_PRODUCT: "IT APPLICATION / PRODUCT",
  PROCESS_OWNER: "PROCESS OWNER",
  UPSTREAM: "UPSTREAM",
  DOWNSTREAM: "DOWNSTREAM",
  REGISTER_BY:
    "Registered by : <[register_id] - [register_by]> - <[register_date]>",
  APPROVE_BY: "Approved by : <[approve_id] - [approve_by]> - <[approve_date]>",
  COMPLEATED_BY:
    "Completed by : <[compleated_id] - [compleated_by]> - <[compleated_date]>",
  CLOSE: "CLOSE",
  CANCEL: "CANCEL",
  REJECT: "REJECT",
  OK: "OK",
  COMPLEATED: "COMPLETED",
  HEADER_MAIN_PROCESS_NAME: "Main Process Name :",
  HEADER_SUB_PROCESS_NAME: "Sub Process Name :",
  LABEL_MAIN_PROCESS_NAME: "Main Process Name",
  HEADER_REJECT: "REJECT REASON",
  LABEL_PROCESS_CATEGORY: "Process Category",
  LABEL_SUB_PROCESS_NAME: "Sub Process Name",
  LABEL_SUB_PROCESS_TYPE: "Sub Process Type",
  LABEL_REMARK: "Remark",
  LABEL_IT_APPLICATION: "IT Application",
  LABEL_END_USER_COMPUTING: "End User Computing",
  LABEL_PRODUCT: "Product",
  LABEL_DIVISION_OWNER: "Division Owner",
  LABEL_SUB_DIVISION: "Sub Division",
  LABEL_APPROVER: "Approver",
  LABEL_UPSTEAM: "Upstream",
  LABEL_INPUT_TYPE: "Input Type",
  LABEL_FREQUENCY: "Frequency",
  LABEL_DOWNSTREAM: "Downstream",
  LABEL_OUTPUT_TYPE: "Output Type",
  TITLE_MODAL_REJECT: "CONFIRM TO REJECT",
  TITLE_MODAL_SUBMIT: "CONFIRM TO APPROVE",
  LABEL_MODAL_REASON: "Reason : ",
  HEADER_MODAL_SUBMIT: "Process ID :",
  HEADER_MODAL_CLOSE: "Confirm to cancel",
  HEADER_MODAL_ERROR: "Error!",
  MESSAGE_MODAL_ERROR: "โปรดบันทึกข้อมูลให้ถูกต้อง",
  LABEL_MODAL_SUBMIT: "CONFIRM",
  LABEL_MODAL_CANCEL: "CANCEL",
  WARNING_CHANGE_NAME:
    "มีการแก้ไขชื่อของ Main Process ซึ่งจะถูกนำไปใช้กับทุก Sub Process ของ Main Process นี้",
  TEXT_GENERATE: "<GENERATE>",
  LABEL_ON_SUBMIT: "SUBMIT",
  LABEL_ON_APPROVE: "APPROVE",
};

export const CONSTANT_PAGE_SUMMARY = {
  LABEL_PROCESS_NAME: "Process Name",
  LABEL_OC_CODE: "OC Code",
  LABEL_PROCESS_STATUS: "Process Status",
  LABEL_MAIN_PROCESS_NAME: "Main Process Name",
  BUTTON_SEARCH: "SEARCH",
  NEW_BUTTON: "+ NEW PROCESS",
  MODAL_TITLE: "NEW PROCESS",
  LABEL_SUBMIT: "NEW",
  LABEL_CANCEL: "CANCEL",
  ALERT_PLEASE_SELECT: "Please select process name",
  SELECT_PROCESS_NAME_PLACEHOLDER: "<MAIN PROCESS NAME>",
};

export const CONSTANT_PAGE_LOGIN = {
  HEADER: "SIAM COMMERCIAL BANK",
  SUB_HEADER: " Operational Risk Data Processing (ORDP)",
  PLACEHOLDER_USERNAME: "USERNAME",
  PLACEHOLDER_PASSWORD: "PASSWORD",
  MODAL_ERROR_MESSAGE: "Authenication failed",
  MODAL_HEADER: "Error!",
  CLOSE_BUTTON: "CLOSE",
  TEXT_VERSION: "ORDP V.1.0.0.0",
};

export const CONSTANT_CARDS_MAIN = [
  {
    image: "/images/process.png",
    name: "PROCESS CREATE AND \n MAINTENANCE",
    path: "/process_create_and_maintenance/summary",
    disabled: false,
  },
  {
    image: "/images/risk.png",
    name: "RISK AND CONTROL \n SELF ASSESSMENT (RCSA)",
    path: "/risk_and_control_self_assessment",
    disabled: true,
  },
  {
    image: "/images/key-performance-indicator.png",
    name: "KEY RISK/CONTROL \n INDICATOR (KRI/KCI)",
    path: "/key_risk_control_indicator",
    disabled: true,
  },
  {
    image: "/images/team.png",
    name: "ISSUE AND ACTION \n MANAGEMENT",
    path: "/issue_and_action_management",
    disabled: true,
  },
  {
    image: "/images/remote-control.png",
    name: "Key control \n Testing",
    path: "/key_control_testing",
    disabled: true,
  },
  {
    image: "/images/7or.png",
    name: "GRC CREATE INCIDENT \n FORM",
    path: "/grc_create_incident_form",
    disabled: true,
  },
  {
    image: "/images/Fact2.png",
    name: "FACT FINDING \n CASE MANAGEMENT",
    path: "/fact_finding_case_management",
    disabled: true,
  },
  {
    image: "/images/analysis.png",
    name: "Business impact \n Analysis (BIA)",
    path: "/business_impact_analysis",
    disabled: true,
  },
  {
    image: "/images/continuous.png",
    name: "Business continuity \n Plan (bcp)",
    path: "/business_continuity_plan",
    disabled: true,
  },
  {
    image: "/images/analytics.png",
    name: "Business continuity \n Plan testing",
    path: "/business_continuity_plan_testing",
    disabled: true,
  },
];

export const CONSTANT_MODAL_LOGOUT = {
  MESSAGE: "Do you want to log out?",
  LABEL_SUBMIT_BUTTON: "OK",
  LABEL_CANCEL_BUTTON: "CANCEL",
};

export const CONSTANT_MODAL_APPROVE_TABLE = {
  LABEL_SUBMIT_BUTTON: "CONFIRM",
  LABEL_CANCEL_BUTTON: "CANCEL",
  TITLE: "CONFIRM TO APPROVE",
  MESSAGE: "Total Approve : [count] Process",
};

export const ERROR_TEXT_DATA_NOT_FOUND = "Data not found";
