import { FunctionGetObjectIsError } from "radium-omnibus";
import { SystemsSetStorage } from "../../../systems/Systems.StorageUser";
import api from "../../../utils/useApi";

const LoginOnSubmit = async (
  form,
  setOpenError,
  navigate,
  setErrorMessages,
  setUser,
  setLoading
) => {
  const thisError = FunctionGetObjectIsError(form);

  try {
    setLoading(true)
    if (thisError?.length > 0) {
      setErrorMessages("Please Enter Username and Password");
      setOpenError(true);
    } else {
      const {
        data: { data },
      } = await api.api_authen_login({
        username: form?.username,
        password: form?.password,
      });

      if (data) {
        SystemsSetStorage({ data, setUser });
        navigate("/");
      }
    }
  } catch (error) {
    const thisError = error?.response?.data?.status?.description;
    setErrorMessages(thisError);
    setOpenError(true);
  } finally {
    setLoading(false)
  }
};

export default LoginOnSubmit;
