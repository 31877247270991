import {
  TAB_ARRAY,
  TYPE_APPROVED,
  TYPE_COMPLETED,
  TYPE_NEW,
  TYPE_REJECTED,
  TYPE_UPDATED,
} from "../../../utils/useConstants";

const ProcessSetTab = (
  setTab,
  status,
  isSuperAdmin,
  isMyApprover,
  isMyRequest,
  superAdminRejectedOnCompleted
) => {
  // console.log("CHECK TAB: ", {
  //   status,
  //   isSuperAdmin,
  //   isMyApprover,
  //   isMyRequest,
  //   superAdminRejectedOnCompleted,
  // });

  if (status === TYPE_NEW && (isMyApprover || isSuperAdmin)) {
    setTab(TAB_ARRAY[1]);
  } else if (status === TYPE_NEW) {
    setTab(TAB_ARRAY[0]);
  } else if (status === TYPE_UPDATED && (isMyApprover || isSuperAdmin)) {
    setTab(TAB_ARRAY[1]);
  } else if (status === TYPE_UPDATED) {
    setTab(TAB_ARRAY[0]);
  } else if (status === TYPE_APPROVED && isSuperAdmin) {
    setTab(TAB_ARRAY[2]);
  } else if (status === TYPE_APPROVED) {
    setTab(TAB_ARRAY[1]);
  } else if (status === TYPE_REJECTED && isMyRequest) {
    setTab(TAB_ARRAY[0]);
  } else if (status === TYPE_REJECTED && superAdminRejectedOnCompleted) {
    setTab(TAB_ARRAY[2]);
  } else if (status === TYPE_REJECTED) {
    setTab(TAB_ARRAY[1]);
  } else if (status === TYPE_COMPLETED) {
    setTab(TAB_ARRAY[2]);
  }
};

export default ProcessSetTab;
