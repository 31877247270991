import create from "zustand";

export const SystemGlobalOptions = create(
  (set, get) => ({
    globalOptions: false,
    setGlobalOptions: (globalOptions) => set(() => ({ globalOptions })),
  }),
  {
    name: "globalOptions",
  }
);
