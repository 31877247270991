import api from "../../../utils/useApi";

const ProcessOnClickApproveAll = async (tran_id = [],setLoading) => {
  try {
    setLoading(true)
    await api.api_process_approve_all({
      tran_id: tran_id,
    });
    window.location.reload();
  } catch (error) {
    console.error("ERROR ProcessOnClickApproveAll", error);
  } finally{
    setLoading(false)
  }
};

export default ProcessOnClickApproveAll;
