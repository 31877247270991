import * as React from "react";
import { FunctionUseId } from "radium-omnibus";
import Select, { components, GroupProps } from "react-select";
import { FixedSizeList as List } from "react-window";
import {
  CONSTANT_PLACEHOLDER,
  TYPE_NA_LABEL,
  TYPE_NA_VALUE,
} from "../../utils/useConstants";
import { ICONS_CHEVRON_DOWN, ICONS_CHEVRON_UP } from "../../utils/useIcons";
import {
  STYLE_SELECT_VALUE_ERROR,
  STYLE_SELECT_VALUE_MULTIPLE,
} from "../../utils/useStyle";
import FieldCheckBox from "./FieldCheckBox";

const TEXT_AUTHOR = "radium-omnibus";
const TYPE_FUNCTION = "function";

const height = 35;

export const FieldSelectMultipleCheckBox = ({ props, hasError }) => {
  const [open, setOpen] = React.useState(false);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const [searchWidth, setSearchWidth] = React.useState(0);

  let thisOptions = props?.options;

  if (thisOptions) {
    thisOptions = [
      { label: TYPE_NA_LABEL, value: TYPE_NA_VALUE },
      ...thisOptions,
    ];
  }

  const id = FunctionUseId();

  React.useEffect(() => {
    if (firstLoad || props.reset) {
      props.setValue(props?.isMultiple ? [] : { label: "", value: "" });
      setFirstLoad(false);
    } else {
      return;
    }
  }, [props.reset]);

  React.useEffect(() => {
    let isMounted = true;
    if (id) {
      if (window !== undefined && window !== null) {
        window.addEventListener("click", (e) => {
          const self = String(e?.target?.parentNode?.className)?.includes(id);
          if (!self) {
            setSearch("");
            setOpen(false);
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [id]);

  React.useEffect(() => {
    if (open) {
      setSearchWidth(refOptions.current?.offsetWidth);
    }

    return () => {};
  }, [open]);

  const isDisabled =
    typeof props.disabled === TYPE_FUNCTION
      ? props.disabled(props)
      : props.disabled;

  const onClickOpen = (e) => {
    e.stopPropagation();
    if (isDisabled) {
      return;
    } else {
      setOpen(!open);
    }
  };

  const refOptions = React.useRef(null);

  return (
    <React.Fragment>
      <div className="flex">
        <div className={props?.classNameLabel}> {props?.label}</div>{" "}
        <span
          style={{
            color: "red",
          }}
        >
          *
        </span>
      </div>
      <div
        id={props.name}
        className={`${TEXT_AUTHOR}-input ${id} ${
          //   typeof props.classNameInput === TYPE_FUNCTION
          //     ? props.classNameInput(props)
          //     : props.classNameInput
          "relative"
        }`}
        onClick={() => {
          if (isDisabled) {
            return;
          } else {
            setOpen(true);
          }
        }}
      >
        <div
          className={`${isDisabled ? "bg-inputDisabled" : ""} ${
            props?.thisValue?.error && hasError
              ? " flex w-full border-[3px] rounded-md p-1  border-red"
              : " flex w-full border-[3px] rounded-md p-1  border-gray-500 "
          } `}
        >
          <div
            onClick={(e) => {
              onClickOpen(e);
            }}
            style={{
              display: "flex",
            }}
            className={`${TEXT_AUTHOR}-values ${
              isDisabled ? "bg-inputDisabled" : "bg-white"
            } ${
              // typeof props.classNameValues === TYPE_FUNCTION
              //   ? props.classNameValues(props)
              //   : props.classNameValues
              "   flex-wrap w-full"
            }`}
          >
            {props.value?.length > 0 ? (
              props.value?.map((item, index) => {
                return (
                  <div
                    className={`${TEXT_AUTHOR}-value ${
                      // typeof props.classNameValue === TYPE_FUNCTION
                      //   ? props.classNameValue(props)
                      //   : props.classNameValue
                      hasError && props?.thisValue?.error
                        ? STYLE_SELECT_VALUE_ERROR
                        : STYLE_SELECT_VALUE_MULTIPLE
                    }`}
                    // onClick={(e) => {
                    //   if (isDisabled) {
                    //     return;
                    //   } else {
                    //     props?.setValue((prev) => {
                    //       let newPrev = [...prev];
                    //       newPrev.splice(index, 1);
                    //       return newPrev;
                    //     });
                    //   }
                    //   e.stopPropagation();
                    // }}
                    key={`key-index-${index}`}
                  >
                    {item?.label}
                    {isDisabled ? (
                      <React.Fragment />
                    ) : (
                      <div
                        onClick={(e) => {
                          if (isDisabled) {
                            return;
                          } else {
                            props?.setValue((prev) => {
                              let newPrev = [...prev];
                              newPrev.splice(index, 1);
                              return newPrev;
                            });
                          }
                          e.stopPropagation();
                        }}
                        className="ml-2 mr-1  my-auto text-24px cursor-pointer "
                      >
                        x
                      </div>
                    )}
                  </div>
                );
              })
            ) : props.placeholder ? (
              props.placeholder
            ) : (
              <div className="my-auto ml-4 h-51px w-full">
                {CONSTANT_PLACEHOLDER}
              </div>
            )}
          </div>
          <div
            onClick={(e) => {
              onClickOpen(e);
            }}
            className={`${isDisabled ? " border-l-0 " : ""} flex w-9 ml-auto `}
          >
            <div className="m-auto">
              {open ? (
                <ICONS_CHEVRON_UP
                  className={`${TEXT_AUTHOR}-icon ${" m-auto"}`}
                  style={
                    props.classNameIcon
                      ? {}
                      : {
                          height: "18px",
                          width: "18px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }
                  }
                />
              ) : (
                <ICONS_CHEVRON_DOWN
                  className={`${TEXT_AUTHOR}-icon ${"my-auto"}`}
                  style={
                    props.classNameIcon
                      ? {}
                      : {
                          height: "18px",
                          width: "18px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }
                  }
                />
              )}
            </div>
          </div>
        </div>

        {open && props?.isSearchable && (
          <React.Fragment>
            <div
              style={{
                width: searchWidth,
              }}
              className={`relative  ${id}`}
            >
              <input
                placeholder="Search"
                className="radium-omnibus-search absolute mt-1 w-full border border-gray-500  p-2 rounded-md text-24px"
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
              <div className="absolute right-3 top-4">
                <img src="/icon/search.png" alt="search" className="h-6 w-6" />
              </div>
            </div>
          </React.Fragment>
        )}

        {open && !isDisabled && (
          <div
            ref={refOptions}
            className={`${TEXT_AUTHOR}-options ${
              //   typeof props.classNameOptions === TYPE_FUNCTION
              //     ? props.classNameOptions(props)
              //     : props.classNameOptions
              "absolute z-10 bg-gray-50  border border-gray-200  min-w-max mt-16 text-gray-700 rounded-lg  h-60 overflow-y-auto"
            }`}
          >
            {thisOptions
              //   ?.filter((i) => {
              //     return props.value.indexOf(i) === -1;
              //   })
              ?.filter((i) => {
                return (
                  (search
                    ? i?.label?.toLowerCase()?.includes(search?.toLowerCase())
                    : true) && i?.value
                );
              })
              ?.map((option, index) => {
                const isSelected = props?.value?.find((item) => {
                  return item?.value === option?.value;
                });

                const isChecked =
                  String(isSelected?.value).length > 0 &&
                  String(isSelected?.value) !== "undefined"
                    ? true
                    : false;

                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isSelected) {
                        props.setValue(
                          props.value.filter((item) => {
                            return item?.value !== option?.value;
                          })
                        );
                      } else {
                        if (
                          props?.value?.some(
                            (item) => item?.value === TYPE_NA_VALUE
                          )
                        ) {
                          return;
                        } else if (option?.value === TYPE_NA_VALUE) {
                          props.setValue(() => {
                            return [option];
                          });
                        } else {
                          props.setValue((prev) => {
                            let temp = [...prev];
                            temp.push(option);
                            return [...temp];
                          });
                        }
                      }
                    }}
                    className="flex hover:bg-scbPrimary group group-hover:text-white px-2 rounded-md "
                  >
                    <FieldCheckBox isChecked={isChecked} />
                    <div
                      key={`key-option-${index}`}
                      className={`${TEXT_AUTHOR}-option ${"my-1 p-2 cursor-pointer group-hover:text-white text-gray-700 w-full text-24px"}`}
                    >
                      {option?.label}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

class MenuList extends React.Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const groupStyles = {
  border: `2px dotted`,
  borderRadius: "5px",
  background: "#f2fcff",
};

const Group = (props) => (
  <div style={groupStyles}>
    <components.Group {...props} />
  </div>
);

export const FieldSelectIsSearchAble = ({
  props,
  options,
  isDisabled = false,
  useReference = false,
  referenceName = "",
  isGroup = false,
  hasError = false,
  placeholder = "",
}) => {
  const [isWhat, setIsWhat] = React.useState(false);
  React.useEffect(() => {
    if (useReference && props.value && referenceName) {
      document.getElementById(`ref_${props.name}`).className =
        props.value[referenceName];
      // อันนี้คือเข้าถึงในตัว data ชืออะไรที่มาจาก dropdown แล้วส่งไปที่ Onsubmit
    }

    return () => {};
  }, [props.value]);

  const isError = props?.thisValue?.error === true && hasError;

  let texpOptionsToFind = [];

  if (isGroup) {
    options?.forEach((item) => {
      item.options?.forEach((item2) => {
        texpOptionsToFind.push({
          label: item2.label,
          value: item2.value,
        });
      });
    });
  }

  return (
    <React.Fragment>
      <Select
        components={{
          // DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        ref={props?.reference}
        onKeyDown={(e) => {
          if (e.keyCode === 32 && e.target.value === "") {
            e.preventDefault();
          }
        }}
        onChange={(e) => {
          props?.setValue(e);
        }}
        placeholder={placeholder ?? CONSTANT_PLACEHOLDER}
        value={
          options && props?.value
            ? isGroup
              ? texpOptionsToFind?.find((item) => {
                  return item.value === props?.value;
                })
              : options?.filter(function (option) {
                  return (
                    option.value === props?.value ||
                    option.value === props?.value?.value
                  );
                })
            : CONSTANT_PLACEHOLDER
        }
        getOptionLabel={(option) => {
          if (option?.options?.length > 0) {
            return option?.options?.find((item) => {
              return item.label === props?.value?.label;
            })?.label;
          } else {
            return option?.label;
          }
        }}
        getOptionValue={(option) => {
          if (option?.options?.length > 0) {
            return option?.options?.find((item) => {
              return item.value === props?.value?.value;
            })?.value;
          } else {
            return option?.value;
          }
        }}
        isClearable={true}
        isDisabled={isDisabled}
        options={options}
        theme={(theme) => {
          return {
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              neutral20: "rgb(107 114 128)",
              primary25: "#EDECFE",
              primary: "#4B2885",
              primary50: "#4B2885",
            },
          };
        }}
        styles={{
          singleValue: (provided, state) => ({
            ...provided,
            color: isDisabled ? "#242424" : "currentcolor",
          }),
          control: (provided, state) => ({
            ...provided,
            height: "64px",
            borderColor: isError ? "0 0 0 2px #FF0000" : "0 0 0 2px #6B7280",
            boxShadow: isError ? "0 0 0 2px #FF0000" : "0 0 0 2px #6B7280",
            background: isDisabled ? "#E3E3E3" : "#FFFFFF",
            paddingLeft: "32px",
            color: isDisabled ? "#242424" : "currentcolor",
          }),
          menu: (provided, state) =>
            isGroup
              ? {
                  ...provided,
                  width: "max-content",
                  minWidth: "100%",
                }
              : {
                  ...provided,
                  width: "max-content",
                  minWidth: "100%",
                },
          groupHeading: (provided, state) => ({
            ...provided,
            color: "#242424",
            fontSize: 26,
            fontWeight: 700,
          }),
          option: (provided, state) =>
            isGroup
              ? {
                  ...provided,
                  paddingLeft: "32px",
                  fontSize: "24px",
                }
              : {
                  ...provided,
                  fontSize: "24px",
                  color: isDisabled ? "#242424" : "currentcolor",
                },
        }}
      />
      {useReference && <div id={`ref_${props.name}`} className={``} />}
    </React.Fragment>
  );
};

// export const FieldSelectIsSearchAbleDuo = ({
//   props,
//   data,
//   isDisabled,
//   keyLableOne,
//   keyLableTwo,
//   keyValueOne,
//   keyValueTwo,
//   nameOne = "",
//   nameTwo = "",
//   duplicateKey = "",
//   labelOne = "",
//   labelTwo = "",
// }) => {
//   const optionsOne = data?.map((item) => {
//     return {
//       label: item[keyLableOne],
//       value: item[keyValueOne],
//       ...item,
//     };
//   });

//   const optionsTwo = data?.map((item) => {
//     return {
//       label: item[keyLableTwo],
//       value: item[keyValueTwo],
//       ...item,
//     };
//   });

//   return (
//     <div className="grid grid-cols-3 gap-4">
//       <div className="col-span-2 pl-8">
//         <div>{labelOne}</div>
//         <CreatableSelect
//           isClearable={true}
//           className="basic-single"
//           classNamePrefix="select"
//           isDisabled={isDisabled}
//           isSearchable={true}
//           name={nameOne}
//           // value={
//           // optionsOne?.filter(function (option) {
//           //   return option.value === props?.value?.[keyValueOne];
//           // }) || props.value[keyValueOne]
//           // }
//           value={
//             props?.value[keyValueOne]?.value
//               ? "HELLO"
//               : optionsOne?.filter(function (option) {
//                   return option.value === props?.value?.[keyValueOne];
//                 })
//           }
//           options={optionsOne}
//           onChange={(e) => {
//             if (e) {
//               props.setValue((prev) => {
//                 if (e?.__isNew__) {
//                   return {
//                     ...prev,
//                     [nameOne]: e,
//                     // [nameTwo]: findSelf.value,
//                     // [duplicateKey]: e[duplicateKey],
//                   };
//                 } else {
//                   // const findSelf = optionsTwo?.find((item) => {
//                   //   return item[duplicateKey] === e[duplicateKey];
//                   // });
//                   return {
//                     ...prev,
//                     [nameOne]: e,
//                     // [nameTwo]: findSelf.value,
//                     // [duplicateKey]: e[duplicateKey],
//                   };
//                 }
//               });
//             } else {
//               props.setValue((prev) => {
//                 return {
//                   ...prev,
//                   [nameOne]: null,
//                   // [nameTwo]: findSelf.value,
//                   // [duplicateKey]: e[duplicateKey],
//                 };
//               });
//             }
//           }}
//           theme={(theme) => ({
//             ...theme,
//             borderRadius: 6,
//             colors: {
//               ...theme.colors,

//               neutral20: "rgb(107 114 128)",

//               primary25: "#EDECFE",
//               primary: "#4B2885",
//               primary50: "#4B2885",
//             },
//           })}
//           styles={{
//             control: (provided, state) => ({
//               ...provided,
//               height: "64px",
//             }),
//             menu: (provided, state) => ({
//               ...provided,
//               // color: "#000",
//               // padding: 4,
//               // fontSize: 14,
//             }),
//           }}
//         />
//       </div>
//       <div className="col-span-1">
//         <div>{labelTwo}</div>
//         <Select
//           className="basic-single"
//           classNamePrefix="select"
//           isDisabled={isDisabled}
//           isSearchable={true}
//           name={nameTwo}
//           value={optionsTwo?.filter(function (option) {
//             return option.value === props?.value?.[keyValueTwo];
//           })}
//           options={optionsTwo}
//           onChange={(e) => {
//             props.setValue((prev) => {
//               // const findSelf = optionsOne?.find((item) => {
//               //   return item[duplicateKey] === e[duplicateKey];
//               // });
//               return {
//                 // [nameOne]: findSelf.value,
//                 ...prev,
//                 [nameTwo]: e.value,
//                 // [duplicateKey]: e[duplicateKey],
//               };
//             });
//           }}
//           theme={(theme) => ({
//             ...theme,
//             borderRadius: 6,
//             colors: {
//               ...theme.colors,

//               neutral20: "rgb(107 114 128)",

//               primary25: "#EDECFE",
//               primary: "#4B2885",
//               primary50: "#4B2885",
//             },
//           })}
//           styles={{
//             control: (provided, state) => ({
//               ...provided,
//               height: "64px",
//             }),
//             menu: (provided, state) => ({
//               ...provided,
//               // color: "#000",
//               // padding: 4,
//               // fontSize: 14,
//             }),
//           }}
//         />
//       </div>
//     </div>
//   );
// };
