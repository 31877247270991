import { FunctionChangeSnakeCaseToNormalCase } from "../../../utils/useFunctions";

const BreadCrumbFunctionGeneratePath = (location) => {
  const pathname = location.pathname;
  const splitPath = pathname.split("/");

  const newPath = splitPath.map((path, index, self) => {
    return {
      path: index > 1 ? `/${self[index - 1]}/${path}` : `/${path}` || "/",
      name:
        FunctionChangeSnakeCaseToNormalCase(path) ||
        (index === 0 ? "Home" : "Landing Page"),
    };
  });

  if (newPath?.length > 3) {
    return newPath.slice(0, 3);
  } else {
    return newPath;
  }
};
export default BreadCrumbFunctionGeneratePath;
