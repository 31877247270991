export const STYLE_INPUT =
  "w-full  border-[3px] border-gray-500 rounded-2xl p-2 rounded-md outline-scbPrimary pl-8 ";

export const STYLE_INPUT_TEXT_AREA =
  "max-h-[90px] w-full  border-[3px] border-gray-500 rounded-2xl p-2 rounded-md outline-scbPrimary pl-8 ";

export const STYLE_INPUT_DISABLE =
  "w-full  border-[3px] border-gray-500 rounded-2xl p-2 rounded-md outline-scbPrimary pl-8 bg-inputDisabled text-lightBlack ";

export const STYLE_INPUT_ERROR =
  "w-full border-[3px] border-red rounded-md outline-red p-2  ";
export const STYLE_SELECT_OPTION =
  "my-1 p-2 hover:bg-scbPrimary hover:text-white text-gray-700";
export const STYLE_SELECT_OPTIONS =
  "z-10 bg-gray-50  border border-gray-200 absolute top-0 w-full mt-16 text-gray-700 rounded-lg h-60 overflow-y-auto";
export const STYLE_SELECT_VALUE =
  "border border-gray-500  pl-8 rounded-md p-2  bg-white cursor-pointer text-gray-700";

export const STYLE_SELECT_VALUE_MULTIPLE =
  "text-white bg-scbPrimary  px-4 m-1 flex  ";

export const STYLE_SELECT_DISABLE =
  "border border-gray-500  rounded-md p-2 bg-inputDisabled cursor-pointer text-gray-700";

export const STYLE_SELECT_VALUE_ERROR =
  "border border-red  rounded-md p-2 bg-gray-100  cursor-pointer text-gray-700";
export const STYLE_INPUT_LOGIN =
  "w-full border border-gray-500 rounded-2xl p-2 rounded-md h-74px placeholder:font-bold pl-8";
export const STYLE_INPUT_LABEL = "font-bold mr-1";

export const STYLE_BODY_MODAL = "text-32px font-bold mt-8 grid grid-cols-3";
export const STYLE_TEXT_MODAL_LABEL = "text-end mr-3 ";
export const STYLE_TEXT_MODAL_VALUE =
  "text-28px font-light col-span-2 my-auto break-all";

export const STYLE_PROCESS_CONTROLLER_GRAY =
  "text-lightBlack grid grid-cols-3  gap-6 pl-5 pr-5 pb-7 pt-2  mt-4 text-30px";
export const STYLE_PROCESS_CONTROLLER_VIOLET = `${STYLE_PROCESS_CONTROLLER_GRAY} bg-scbSecondary`;
export const STYLE_TITLE_CONTROLLER =
  "col-span-3 text-32px text-lightBlack font-bold";

export const STYLE_REMARK_CORE_CONTROLLER = "col-span-3 ml-24";
