import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const InfoModal = ({ showPopup, setShowPopup }) => {
  const thisRef = React.useRef(null);
  if (showPopup) {
    return (
      <Transition appear show={showPopup} as={Fragment}>
        <Dialog
          initialFocus={thisRef}
          as="div"
          className="relative z-10"
          onClose={() => setShowPopup(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 mt-12 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden  bg-white  text-left align-middle shadow-xl transition-all">
                  <img src="/images/mainpopup.png"></img>
                  <div
                    onClick={() => setShowPopup(false)}
                    className="absolute cursor-pointer top-0 right-4 font-light text-38px text-textGrey"
                  >
                    X
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  } else {
    return <React.Fragment />;
  }
};
