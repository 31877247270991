import React from "react";

const TabMain = ({ actions = [], value = "", isMyRequest, isMyApprover }) => {
  return (
    <div
      className={`${
        isMyRequest || isMyApprover ? " font-bold " : " font-normal "
      }  z-0 flex space-x-14 pb-2 text-22px border-gray-300 border-b my-30px w-3/4 justify-end`}
    >
      {actions?.map((action, index, self) => {
        const isActive = self.findIndex((item) => item === value) >= index;
        return (
          <div key={`key-of-action-${index}`}>
            <div
              className={` ${
                isActive
                  ? " bg-scbPrimary text-white "
                  : " bg-white text-gray-300 border border-gray-300 "
              } rounded-full w-8 h-8 text-24px  m-auto relative`}
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                {index + 1}
              </div>
              {index < actions.length - 1 && (
                <div className="border w-40 border-b-gray-700  absolute top-1/2  transform  -translate-y-1/2 ml-8" />
              )}
            </div>
            <div
              className={`${
                isActive ? " text-violet-800 " : " text-gray-700 "
              }`}
            >
              {action}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default TabMain;
