
import create from "zustand";

export const SystemStoreLoading = create(
    (set, get) => ({
      loading: false,
      setLoading: (loading) => set(() => ({ loading })),
    }),
    {
      name: "loading",
    }
  
);
