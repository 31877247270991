import { FunctionCloneDeep } from "radium-omnibus";
import React from "react";
import { useNavigate } from "react-router-dom";
import ModalMain from "../../../components/modal/Modal.Main";
import TableMain from "../../../components/table/Table.Main";
import { SystemStoreLoading } from "../../../systems/Systems.Loading";
import { SystemStoreUser } from "../../../systems/Systems.StorageUser";
import {
  CONSTANT_MODAL_APPROVE_TABLE,
  TYPE_APPROVED,
  TYPE_NEW,
  TYPE_UPDATED,
} from "../../../utils/useConstants";
import { FunctionCheckIsSuperAdmin } from "../../../utils/useFunctions";

import ProcessFormatDataTable from "../functions/Process.FormatDataTable";
import ProcessOnClickApproveAll from "../functions/Process.OnClickApproveAll";

const ProcessAddTempSelect = (data) => {
  let cloneData = FunctionCloneDeep(data);
  const result = cloneData.map((item) => {
    item.selected = false;
    return item;
  });
  return result;
};

const ProcessTable = ({
  data = [],
  header = [],
  setPage = () => {
    alert("please send setPage to this component");
  },
  page = 1,
  pagination = false,
  selected = false,
}) => {
  const navigate = useNavigate();
  const [dataTable, setDataTable] = React.useState([]);
  const setLoading = SystemStoreLoading((state) => state.setLoading);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const user = SystemStoreUser((state) => state.user);

  const isSelected = dataTable
    ?.filter((item) => item.selected === true)
    .map((item) => item.tran_id);

  React.useEffect(() => {
    if (data) {
      const newData = ProcessAddTempSelect(data);
      setDataTable(newData);
    }
    return () => {};
  }, [data]);

  React.useEffect(() => {
    if (selected) {
      const newData = ProcessAddTempSelect(data);
      let selectData = newData.map((item) => {
        const isMyApprover = item?.approver_id === user?.user_id;
        const canApprove =
          (item.tran_status === TYPE_UPDATED ||
            item.tran_status === TYPE_NEW) &&
          isMyApprover;
        // ||
        // (item.tran_status === TYPE_APPROVED && user?.oc_code === "7214");
        if (canApprove) {
          item.selected = true;
        }
        return item;
      });
      setDataTable(selectData);
    } else {
      const newData = ProcessAddTempSelect(data);
      setDataTable(newData);
    }
  }, [selected]);

  const isSuperAdmin = FunctionCheckIsSuperAdmin(user);

  return (
    <React.Fragment>
      <TableMain
        page={page}
        setPage={setPage}
        headers={header}
        dataCells={ProcessFormatDataTable(
          dataTable,
          navigate,
          true,
          setDataTable,
          user,
          page
        )}
        pagination={pagination}
        // onClickRow={(row) => {
        //   navigate(`/process_create_and_maintenance/process/${row?.process}`);
        // }}
      />
      {/* {isSelected?.length > 0 && ( */}

      {(user?.role === "BU APPROVAL" ||
        (isSuperAdmin && isSelected?.length > 0)) && (
        <button
          onClick={() => {
            if (isSelected?.length > 0) {
              setOpenConfirm(true);
              // ProcessOnClickApproveAll(isSelected, setLoading);
            } else {
              return;
            }
          }}
          className={
            isSelected?.length > 0
              ? "bg-white rounded-md w-207px border border-scbPrimary text-scbPrimary shadow-xl  cursor-pointer  mx-auto mt-6 text-[30px] font-bold h-12 mb-8"
              : "rounded-md w-207px text-white  shadow-xl  cursor-pointer bg-grayDisabled mx-auto mt-6 text-[30px] font-bold h-12 mb-8"
          }
        >
          APPROVE
        </button>
      )}
      {/* )} */}
      {openConfirm && (
        <ModalMain
          labelCancel={CONSTANT_MODAL_APPROVE_TABLE.LABEL_CANCEL_BUTTON}
          labelSubmit={CONSTANT_MODAL_APPROVE_TABLE.LABEL_SUBMIT_BUTTON}
          title={CONSTANT_MODAL_APPROVE_TABLE.TITLE}
          open={openConfirm}
          maxWidth="max-w-2xl"
          setOpen={setOpenConfirm}
          onClose={() => {
            setOpenConfirm(false);
          }}
          body={
            <div className="text-center text-32px font-bold">
              <div className="text-32px mt-6">
                {CONSTANT_MODAL_APPROVE_TABLE.MESSAGE.replace(
                  "[count]",
                  isSelected?.length
                )}
              </div>
            </div>
          }
          onClickSubmit={() => {
            ProcessOnClickApproveAll(isSelected, setLoading);
            // navigate("/process_create_and_maintenance");
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ProcessTable;
