import React from "react";
import ReactLoading from "react-loading";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const Loading = ({ isLoading }) => {
  if (isLoading) {
    return (
      <Transition appear show={isLoading} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-0" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-40 transform overflow-hidden  bg-white   text-left align-middle  transition-all rounded-3xl bg-opacity-90">
                  <ReactLoading
                    type={"bubbles"}
                    color={"#4B2885"}
                    height={"100%"}
                    width={"100%"}
                  />
                  {/* <div className="w-full text-center text-28px pb-4">Loading ...</div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  } else {
    return <React.Fragment />;
  }
};
