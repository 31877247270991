import {
  FunctionCheckIsEqual,
  FunctionCloneDeep,
  FunctionGetObjectIsError,
} from "radium-omnibus";
import api from "../../../utils/useApi";
import {
  DOM_NAME_PROCESS_ID,
  TYPE_APPROVED,
  TYPE_NEW,
  TYPE_REGISTER,
  TYPE_REJECTED,
  TYPE_UPDATED,
} from "../../../utils/useConstants";

const ProcessMappingData = (data, type) => {
  let cloneData = FunctionCloneDeep(data);
  const findApproverID =
    document?.getElementById("ref_approver_name")?.className;
  // const findProcessID = document?.getElementById("ref_process_cat")?.className;
  // const findSubprocessID = document?.getElementById(
  //   "ref_sub_process_type"
  // )?.className;

  const isNewApprover = !(String(findApproverID) === "undefined");

  console.log("CHECK POINT (ProcessMappingData) : ", {
    approver_id: cloneData?.approver_id,
    isNewApprover,
    type,
    data,
  });

  cloneData = {
    ...cloneData,
    it_apps: cloneData?.it_apps?.map((item) => {
      return {
        app_id: item?.value,
        app_name: item?.label,
      };
    }),
    products: cloneData?.products?.map((item) => {
      return {
        product_id: item?.value,
        product_name: item?.label,
      };
    }),
    tran_type: TYPE_REGISTER,
    // process_id: findProcessID,
    // process_name: data?.temp_process?.process_name,
    // process_cat: data?.temp_process?.process_cat,
    // sub_process_id: findSubprocessID,
    // sub_process_name: data?.temp_sub_process?.sub_process_name,
    // sub_process_type: data?.temp_sub_process?.sub_process_type,
    // approver_id: type ? cloneData?.approver_id : findApproverID,
    approver_id: isNewApprover ? findApproverID : cloneData?.approver_id,
  };

  // delete cloneData.temp_process;
  // delete cloneData.temp_sub_process;

  return cloneData;
};

const isThisEqual = (originalData, form) => {
  const one = {
    process_name: originalData?.process_name,
    process_cat: originalData?.process_cat,
    sub_process_name: originalData?.sub_process_name,
    sub_process_type: originalData?.sub_process_type,
    sub_process_remark: originalData?.sub_process_remark,
    it_apps: originalData?.it_apps,
    end_user_comp: originalData?.end_user_comp,
    products: originalData?.products,
    division_owner: originalData?.division_owner,
    sub_division: originalData?.sub_division,
    approver_name: originalData?.approver_name,
    upstream: originalData?.upstream,
    up_input_type: originalData?.up_input_type,
    up_freq: originalData?.up_freq,
    up_remark: originalData?.up_remark,
    down_stream: originalData?.down_stream,
    dn_input_type: originalData?.dn_input_type,
    dn_freq: originalData?.dn_freq,
    dn_remark: originalData?.dn_remark,
  };
  const two = {
    process_name: form?.process_name,
    process_cat: form?.process_cat,
    sub_process_name: form?.sub_process_name,
    sub_process_type: form?.sub_process_type,
    sub_process_remark: form?.sub_process_remark,
    it_apps: form?.it_apps?.map((item) => {
      return {
        app_id: item?.value,
        app_name: item?.label,
      };
    }),
    end_user_comp: form?.end_user_comp,
    products: form?.products?.map((item) => {
      return {
        product_id: item?.value,
        product_name: item?.label,
      };
    }),
    division_owner: form?.division_owner,
    sub_division: form?.sub_division,
    approver_name: form?.approver_name,
    upstream: form?.upstream,
    up_input_type: form?.up_input_type,
    up_freq: form?.up_freq,
    up_remark: form?.up_remark,
    down_stream: form?.down_stream,
    dn_input_type: form?.dn_input_type,
    dn_freq: form?.dn_freq,
    dn_remark: form?.dn_remark,
  };
  const isEqual = FunctionCheckIsEqual(one, two);
  return isEqual;
};

const ProcessOnSubmit = async (
  id,
  form,
  type,
  setOpenError,
  setHasError,
  setOpenConfirm,
  setOpenSuccess,
  setErrorMessages,
  setLoading,
  setInitData,
  originalData,
  isMyRequest,
  isMyApprover,
  isSuperAdmin,
  loading
) => {
  if (loading) {
    return;
  }
  const thisError = FunctionGetObjectIsError(form);
  let isEqual = false;

  if (thisError?.length === 0) {
    isEqual = isThisEqual(originalData, form);
  }

  console.log("CHECK POINT (ProcessOnSubmit) : ", {
    id,
    formData: form,
    thisError,
    mappingData:
      thisError?.length > 0 ? "Error" : ProcessMappingData(form, type),
    type,
    originalData,
    isEqual,
  });

  if (
    isEqual &&
    (type === TYPE_REJECTED ||
      ((type === TYPE_NEW || type === TYPE_UPDATED) && isMyRequest))
  ) {
    setErrorMessages("No changes detected");
    setOpenConfirm(false);
    // setHasError(true);
    setOpenError(true);
    return;
  }

  try {
    setLoading(true);
    if (thisError?.length > 0) {
      setOpenConfirm(false);
      // setHasError(true);
      setOpenError(true);
      setLoading(false);
    } else {
      if (
        id !== "create" &&
        (type === TYPE_NEW || type === TYPE_UPDATED) &&
        isMyApprover &&
        !(isMyRequest && isSuperAdmin && type === TYPE_APPROVED)
      ) {
        //Update status process to approve
        await api.api_process_approve({
          id: id,
        });
        // window.location.reload();
      } else if (id !== "create" && type === TYPE_APPROVED) {
        //Update status process to complete
        await api.api_process_complete({
          id: id,
        });
        // window.location.reload();
      } else if (id !== "create" && isMyRequest) {
        // Update process
        await api.api_process_update({
          data: ProcessMappingData(form, type),
          id: id,
        });
      } else {
        // Create new process
        const { data } = await api.api_process_create({
          data: ProcessMappingData(form, type),
        });

        if (data?.data?.sub_process_id) {
          setInitData((prev) => {
            let temp = { ...prev };
            temp.process_id = "create";
            temp.sub_process_id = data?.data?.sub_process_id;

            return { ...temp };
          });
        }
      }

      setOpenConfirm(false);
      setOpenSuccess(true);
      setLoading(false);
    }
  } catch (error) {
    const thisError = error?.response?.data?.status?.description;
    setErrorMessages(thisError);
    setOpenConfirm(false);
    setOpenError(true);
    setLoading(false);
  }
};

export default ProcessOnSubmit;
