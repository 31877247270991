import { Controllers, FunctionCloneDeep } from "radium-omnibus";
import React from "react";
import ProcessSetPageForLoad from "../../pages/process/functions/Process.SetPageForLoad";
import { ICON_CHEVRON_LEFT, ICON_CHEVRON_RIGHT } from "../../utils/useIcons";

const StylePegination =
  "  md:flex justify-center border border-gray-200  mr-1 text-28px p-3 items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded ";

const RenderEachButton = ({ count, value, setValue }) => {
  return (
    <div
      onClick={() => {
        ProcessSetPageForLoad(setValue, count);
      }}
      className={`${
        count === value ? " text-white bg-scbPrimary text-28px" : " text-28px"
      } ${StylePegination}`}
    >
      {count}
    </div>
  );
};

const PaginationControllerMemo = React.memo(({ setValue, pageCount }) => {
  return (
    <div className="flex ">
      <Controllers
        classNameSuperCore="my-auto flex"
        showError={false}
        showErrorAlert={false}
        fields={[
          {
            name: "page",
            type: "number",
            classNameInput:
              "w-16 mt-auto border h-11 border-gray-400 rounded px-2",
            classNameWrapper: "my-auto",
            limit: pageCount,
            min: 1,
            errorCustoms: (props) => {
              const hasDot = String(props?.value).includes(".");
              if (hasDot) {
                return "Please enter a valid number";
              } else {
                return "";
              }
            },
            onKeyPress: ({ event, value }) => {
              if (value > 0 && value <= pageCount && event.key === "Enter") {
                const hasDot = String(value).includes(".");
                if (!hasDot) {
                  ProcessSetPageForLoad(setValue, value);
                }
              }
              return;
            },
          },
          {
            type: "submit",
            labelSubmit: "Go",
            classNameSubmit:
              " text-gray-500 border border-gray-400 text-28px px-4 rounded  my-auto ml-1",
            classNameCore: "col-span-1",
            onSubmit: (data) => {
              if (data?.page > 0 && data?.page <= pageCount) {
                ProcessSetPageForLoad(setValue, data?.page);
              } else {
                return;
              }
            },
          },
        ]}
      />
    </div>
  );
});

const PaginationMain = ({
  count = 10,
  amount = 10,
  value = 1,
  setValue = () => {},
}) => {
  const pageCount = Math.ceil(count / amount);
  const thisArray = new Array(pageCount).fill("");

  const isDisabled = value === pageCount;

  return (
    <div className="flex justify-between mx-8 ">
      <div className="text-28px my-auto">
        {count > 0 ? 1 + (value - 1) * 10 : 0} -{" "}
        {value * 10 > count ? count : value * 10} of {count} Records
      </div>
      <div className="flex flex-row">
        <div className=" flex flex-col items-center  text-28px text-gray-500">
          <div className="flex ">
            <button
              onClick={(e) => {
                e.stopPropagation();

                ProcessSetPageForLoad(setValue, value - 1);
              }}
              disabled={value === 1}
              className={
                "p-1 flex justify-center items-center  cursor-pointer border border-gray-300 mr-2 text-28px rounded-md " +
                (value === 1 ? "opacity-50" : "")
              }
            >
              <ICON_CHEVRON_LEFT />
              Previous
            </button>
            <div className="flex rounded-full ">
              {thisArray?.map((_, i) => {
                const firstCondition = value <= 6 && i >= 10;
                const secondCondition =
                  value > 6 &&
                  value < pageCount - 5 &&
                  (i < value - 6 || i > value + 3);
                const thirdCondition =
                  value >= pageCount - 5 && i < pageCount - 10;

                const isHidden =
                  firstCondition || secondCondition || thirdCondition;
                if (isHidden) {
                  return <React.Fragment />;
                } else {
                  return (
                    <RenderEachButton
                      key={i}
                      count={i + 1}
                      value={value}
                      setValue={setValue}
                    />
                  );
                }
              })}
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();

                ProcessSetPageForLoad(setValue, value + 1);
              }}
              disabled={isDisabled}
              className={`${
                isDisabled
                  ? " ml-2 p-1 flex justify-center items-center  cursor-pointer border border-gray-300 mr-2 text-28px rounded-md opacity-50 "
                  : " ml-2 text-28px flex justify-center items-center cursor-pointer border border-gray-300 p-1 pl-3 pr-2 rounded "
              }`}
            >
              <div className="my-auto"> Next</div>
              <ICON_CHEVRON_RIGHT />
            </button>
          </div>
        </div>
        <div className="my-auto mx-2 text-28px">Total {pageCount} pages</div>
        <div className="flex space-x-2">
          <div className="my-auto text-28px ml-2"> Go to Page</div>
          <PaginationControllerMemo setValue={setValue} pageCount={pageCount} />
        </div>
      </div>
    </div>
  );
};

export default PaginationMain;
