import { FunctionCloneDeep } from "radium-omnibus";
import api from "../../../utils/useApi";
import ProcessSetTab from "./Process.SetTab";

const ProcessLoadData = async (
  setData,
  id,
  setTab,
  setLoading,
  isSuperAdmin,
  setOriginalData,
  user
) => {
  setLoading(true);
  const {
    data: { data },
  } = await api.api_process_by_id({ id });
  setLoading(false);
  if (data) {
    setOriginalData(FunctionCloneDeep(data));
    setData({
      ...data,
      temp_process: {
        process_id: data?.process_id,
        process_name: data?.process_name,
        process_cat: data?.process_cat,
      },
      temp_sub_process: {
        sub_process_id: data?.sub_process_id,
        sub_process_name: data?.sub_process_name,
        sub_process_type: data?.sub_process_type,
      },
      it_apps: data?.it_apps?.map((item) => {
        return {
          value: item?.app_id,
          label: item?.app_name,
        };
      }),
      products: data?.products?.map((item) => {
        return {
          value: item?.product_id,
          label: item?.product_name,
        };
      }),
    });

    const isMyRequest = data?.request_id === user?.user_id;
    const isMyApprover = data?.approver_id === user?.user_id;

    ProcessSetTab(
      setTab,
      data?.tran_status,
      isSuperAdmin,
      isMyApprover,
      isMyRequest,
      data?.complete_id !== null
    );
  }
};

export default ProcessLoadData;
