//LIBRARYS
import React, { useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

//PAGES
import HomeMain from "./pages/home/Home.Main";

//COMPONENTS
import NavbarMain from "./components/navbar/Navbar.Main";
import ProcessMain from "./pages/process/Process.Main";
import ProcessSummary from "./pages/process/Process.Summary";
import ProcessProcess from "./pages/process/Process.Process";
import LoginMain from "./pages/login/Login.Main";
import SystemsProtectedRoute from "./systems/Systems.ProtectRoute";
import SystemsRefreshToken from "./systems/Systems.RefreshToken";
import {
  SystemCapture,
  SystemLogout,
  SystemStoreUser,
} from "./systems/Systems.StorageUser";
import { CONSTANT_CARDS_MAIN } from "./utils/useConstants";
import { Loading } from "./components/modal/Loading";
import { SystemStoreLoading } from "./systems/Systems.Loading";
import FunctionFirstLoadPromise from "./utils/FunctionFirstLoadPromise";
import { SystemGlobalOptions } from "./systems/Systems.GlobalOptions";
import api from "./utils/useApi";
import { apiBaseURL } from "./utils/useConfig";

const routes = [
  {
    path: "/",
    element: <HomeMain CARDS={CONSTANT_CARDS_MAIN} />,
    protected: true,
  },
  {
    path: "/login",
    element: <LoginMain />,
  },
  {
    path: "/process_create_and_maintenance",
    element: <ProcessMain />,
    protected: true,
  },
  {
    path: "/process_create_and_maintenance/process/:id",
    element: <ProcessProcess />,
    protected: true,
  },
  {
    path: "/process_create_and_maintenance/summary",
    element: <ProcessSummary />,
    protected: true,
  },
];
const useBeforeunload = (handler) => {
  const [loaded, setLoaded] = React.useState(false);
  const eventListenerRef = React.useRef();
  const eventListenerRefTwo = React.useRef();

  React.useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [setLoaded]);

  React.useEffect(() => {
    eventListenerRef.current = (event) => {
      const returnValue = handler?.(event);
      if (typeof returnValue === "string") {
        return (event.returnValue = returnValue);
      }
      if (event.defaultPrevented) {
        return (event.returnValue = "");
      }
    };
  }, [handler, loaded]);

  React.useEffect(() => {
    eventListenerRefTwo.current = (event) => {
      const returnValue = () => {
        sessionStorage.setItem("check-status", "reset");
      };
      if (typeof returnValue === "string") {
        return (event.returnValue = returnValue);
      }
      if (event.defaultPrevented) {
        return (event.returnValue = "");
      }
    };
  }, [loaded]);

  React.useEffect(() => {
    const eventListener = (event) => eventListenerRef.current(event);
    const eventListenerTwo = (event) => eventListenerRefTwo.current(event);
    window.onbeforeunload = eventListener;
    window.onpagehide = eventListener;

    window.addEventListener("keydown", (event) => {
      if (event.keyCode === 116) {
        window.onbeforeunload = eventListenerTwo;
        window.onpagehide = eventListenerTwo;
      }
      if (event.keyCode === 82 && event.ctrlKey) {
        window.onbeforeunload = eventListenerTwo;
        window.onpagehide = eventListenerTwo;
      }
      if (event.keyCode === 82 && event.ctrlKey && event.shiftKey) {
        window.onbeforeunload = eventListenerTwo;
        window.onpagehide = eventListenerTwo;
      }

      if (event.keyCode === 82 && event.metaKey) {
        window.onbeforeunload = eventListenerTwo;
        window.onpagehide = eventListenerTwo;
      }
      if (event.keyCode === 82 && event.metaKey && event.shiftKey) {
        window.onbeforeunload = eventListenerTwo;
        window.onpagehide = eventListenerTwo;
      }
    });

    return () => {
      window.onbeforeunload = null;
      window.onpagehide = null;
    };
  }, []);
};

const App = () => {
  const [stopRefreshToken, setStopRefreshToken] = React.useState(false);
  const [checkInitialApp, setCheckIntialApp] = React.useState(false);
  const [checkInterval, setCheckInterval] = React.useState(false);
  const navigate = useNavigate();
  const user = SystemStoreUser((state) => state.user);
  const loading = SystemStoreLoading((state) => state.loading);
  const location = useLocation();

  const [token, setToken] = useState({
    access_token: null,
    refresh_token: null,
  });

  React.useEffect(() => {
    const access_token = sessionStorage.getItem("access_token");
    const refresh_token = sessionStorage.getItem("refresh_token");

    setToken({ access_token, refresh_token });
  }, [user]);

  const setGlobalOptions = SystemGlobalOptions(
    (state) => state.setGlobalOptions
  );

  React.useEffect(() => {
    setCheckIntialApp(true);
    const interval = setInterval(() => {
      setCheckInterval(!checkInterval);
      SystemCapture(false, setStopRefreshToken);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (checkInitialApp && !user) {
      navigate("/login");
    }
  }, [checkInitialApp, user]);

  //  React.useEffect(() => {
  //    if (location.pathname === "/login") {
  //      return;
  //    }
  //    const FunctionClick = async (event) => {
  //      if (token.refresh_token) {
  //        await api.api_authen_logout({ refresh_token: token.refresh_token });
  //      }
  //    };

  //    window.addEventListener("beforeunload", FunctionClick);

  //    return () => {
  //      window.removeEventListener("beforeunload", FunctionClick);
  //    };
  //  });

  //////////////////////////////////
  // React.useEffect(() => {
  //   if (location.pathname === "/login") {
  //     return;
  //   }

  // const FunctionClick = async (event) => {
  //   if (token.refresh_token) {
  //     await api.api_authen_logout({ refresh_token: token.refresh_token });
  //     sessionStorage.clear();
  //     window.location.reload();
  //     event.preventDefault();
  //     event.stopImmediatePropagation();
  //     event.stopPropagation();
  //     event.returnValue = null;
  //     return null;
  //   }
  // };

  // const FunctionClickReload = async (event) => {
  //   sessionStorage.setItem("check-status", "reset");
  //   event.preventDefault();
  //   event.stopImmediatePropagation();
  //   event.stopPropagation();
  //   event.returnValue = null;
  //   return null;
  // };

  //   window.onbeforeunload = FunctionClick;

  // window.addEventListener("keydown", (event) => {
  //   if (event.keyCode === 116) {
  //     window.onbeforeunload = FunctionClickReload;
  //   }
  //   if (event.keyCode === 82 && event.ctrlKey) {
  //     window.onbeforeunload = FunctionClickReload;
  //   }
  //   if (event.keyCode === 82 && event.ctrlKey && event.shiftKey) {
  //     window.onbeforeunload = FunctionClickReload;
  //   }

  //   if (event.keyCode === 82 && event.metaKey) {
  //     window.onbeforeunload = FunctionClickReload;
  //   }
  //   if (event.keyCode === 82 && event.metaKey && event.shiftKey) {
  //     window.onbeforeunload = FunctionClickReload;
  //   }
  //   });

  //   return () => {
  //     window.onbeforeunload = null;
  //     window.removeEventListener("keydown", FunctionClick);
  //   };
  // });

  useBeforeunload(async () => {
    if (token.refresh_token) {
      await api.api_authen_logout({ refresh_token: token.refresh_token });
      sessionStorage.clear();
      window.location.reload();
    }
  });
  ////////////////////////////////

  // React.useEffect(() => {
  //   if (location.pathname === "/login") {
  //     return;
  //   }
  //   window.addEventListener("beforeunload", (event) => {
  //     window.localStorage.isMySessionActive = "true";
  //     sessionStorage.setItem("check-status", "reset");
  //   });
  //   window.onunload = function (e) {
  //     const newTabCount = localStorage.getItem("tabsOpen");
  //     if (newTabCount !== null) {
  //       localStorage.setItem("tabsOpen", newTabCount - 1);
  //     }
  //   };
  // }, [location.pathname]);

  // React.useEffect(() => {
  //   asyncFunction();
  // }, [location.pathname, user]);

  // const asyncFunction = async () => {
  //   if (window !== undefined && window !== null) {
  //     if (location.pathname === "/login") {
  //       return;
  //     }
  //     const tabsOpen = localStorage.getItem("tabsOpen");
  //     if (tabsOpen == null) {
  //       localStorage.setItem("tabsOpen", 1);
  //     } else {
  //       localStorage.setItem("tabsOpen", 1);
  //     }

  //     window.onunload = function (e) {
  //       const newTabCount = localStorage.getItem("tabsOpen");
  //       if (newTabCount !== null) {
  //         localStorage.setItem("tabsOpen", newTabCount - 1);
  //       }
  //     };
  //     if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
  //       window.localStorage.isMySessionActive = "false";
  //     } else {
  //       const newTabCount2 = localStorage.getItem("tabsOpen");
  //       let value = localStorage.getItem("isMySessionActive");
  //       if (value == "true") {
  //         if (newTabCount2 - 1 == 0) {
  //           if (token.refresh_token) {
  //             await api.api_authen_logout({
  //               refresh_token: token.refresh_token,
  //             });
  //             // api.api_authen_logout({ refresh_token: token.refresh_token });
  //             sessionStorage.clear();
  //             localStorage.setItem("pass", true);
  //           }
  //         } else {
  //           window.localStorage.isMySessionActive = "false";
  //         }
  //       }
  //     }
  //   }
  // };

  React.useEffect(() => {
    let mounted = true;
    const isLoggingIn = location.pathname === "/login";
    if (setGlobalOptions && !isLoggingIn && user) {
      FunctionFirstLoadPromise(setGlobalOptions);
    }
    return () => {
      mounted = false;
    };
  }, [setGlobalOptions, user]);

  return (
    <React.Fragment>
      <div onClickCapture={() => SystemCapture(true, setStopRefreshToken)}>
        <SystemsRefreshToken stopRefreshToken={stopRefreshToken} />
        {user && <NavbarMain />}
        <Routes>
          {routes.map((route, index) => {
            if (route.protected) {
              return (
                <Route
                  key={`key-non-protec-${index}`}
                  path={route.path}
                  element={
                    <SystemsProtectedRoute
                      user={user}
                      key={`key-route-protect-${index}`}
                    >
                      {route.element}
                    </SystemsProtectedRoute>
                  }
                />
              );
            } else {
              return (
                <Route
                  key={`key-route-non-protect-${index}`}
                  path={route.path}
                  element={route.element}
                />
              );
            }
          })}
        </Routes>
        <Loading isLoading={loading} />
      </div>
    </React.Fragment>
  );
};

export default App;
