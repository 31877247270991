import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

const SystemsProtectedRoute = ({ children, user = false }) => {
  // const [open, setOpen] = React.useState(false);

  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const { user } = useSelector((state) => state.auth);

  if (!user) {
    return <Navigate to="/Login" replace />;
  }

  return <div>{children}</div>;
};

export default SystemsProtectedRoute;
