import React from "react";
import BreadCrumbMain from "../../components/breadcrumb/BreadCrumb.Main";
import { Controllers } from "radium-omnibus";
import {
  STYLE_INPUT,
  STYLE_INPUT_ERROR,
  STYLE_INPUT_LABEL,
} from "../../utils/useStyle";
import ProcessTable from "./render/Process.Table";
import ProcessButtonNewProcess from "./render/Process.ButtonNewProcess";
import {
  OPTINOS_PROCESS_STATUS,
  OPTIONS_DIVISION_OWNER,
} from "../../utils/useOptions";
import ProcessLoadDataTable from "./functions/Process.LoadDataTable";
import { CONSTANT_PAGE_SUMMARY, DEFAULT_PAGE } from "../../utils/useConstants";
import { FieldSelectIsSearchAble } from "../../components/fields/Field.Select";
import { SystemStoreLoading } from "../../systems/Systems.Loading";
import { SystemStoreUser } from "../../systems/Systems.StorageUser";
import FieldCheckBox from "../../components/fields/FieldCheckBox";
import { FunctionCheckIsSuperAdmin } from "../../utils/useFunctions";

const ProcessSummaryControllerMemo = React.memo(
  ({ setSearch, hasError, setPage, setHasSearch }) => {
    const [loadData, setLoadData] = React.useState(false);

    React.useEffect(() => {
      setLoadData(true);
    }, []);

    const reset = sessionStorage.getItem("check-status") === "reset";
    let loadParams = JSON.parse(sessionStorage.getItem("process_search"));

    return (
      <Controllers
        showError={false}
        loadData={!reset ? loadParams : {}}
        classNameSuperCore="flex text-30px w-full"
        fields={[
          {
            type: "text",
            label: CONSTANT_PAGE_SUMMARY.LABEL_PROCESS_NAME,
            classNameLabel: STYLE_INPUT_LABEL,
            name: "process_name",
            classNameCore:
              "w-[500px] rounded-xl w-392.5px focus:border-scbPrimary ",
            // classNameInput: STYLE_INPUT + "  outline-scbPrimary font-bold",
            classNameInput: (props) => {
              if (props?.thisValue?.error && hasError) {
                return STYLE_INPUT_ERROR;
              } else {
                return STYLE_INPUT;
              }
            },
            // no_space: true,
          },
          {
            name: "org_id",
            classNameLabel: STYLE_INPUT_LABEL,
            classNameCore: "w-[500px] ml-4 ",
            type: "customs",
            label: CONSTANT_PAGE_SUMMARY.LABEL_OC_CODE,
            fieldCustoms: (props) => {
              const options = OPTIONS_DIVISION_OWNER(loadData);

              return (
                <FieldSelectIsSearchAble
                  isGroup={true}
                  props={props}
                  options={options}
                />
              );
            },
          },
          {
            classNameCore: "w-228px ml-4",
            type: "customs",
            classNameLabel: STYLE_INPUT_LABEL,
            label: CONSTANT_PAGE_SUMMARY.LABEL_PROCESS_STATUS,
            name: "tran_status",
            fieldCustoms: (props) => {
              return (
                <FieldSelectIsSearchAble
                  options={OPTINOS_PROCESS_STATUS}
                  props={props}
                />
              );
            },
          },
          {
            type: "submit",
            labelSubmit: CONSTANT_PAGE_SUMMARY.BUTTON_SEARCH,
            classNameSubmit:
              "ml-8 bg-scbPrimary rounded text-white font-bold h-16 w-153.7px mt-auto ml-10",
            onSubmit: (data) => {
              // const value = Object?.values(data)?.every((row) => row === "");
              // if (!value) {

              // const findOrgId = document?.querySelector("#ref_watch_org_id"); // Search field ref value ว่ามัน Search ด้วย spacebar ไหม
              // if (findOrgId?.className === "spacebar") {
              //   data.org_id = "%20%20%20";
              // }

              setHasSearch(true);
              setPage(DEFAULT_PAGE);
              setSearch(data);
              // } else {
              // return;
              // }
            },
          },
          {
            type: "none",
            classNameCore: "ml-auto mt-auto",
            render: () => {
              return (
                <div className="">
                  <ProcessButtonNewProcess />
                </div>
              );
            },
          },
        ]}
      />
    );
  }
);

const ProcessSummary = () => {
  const [page, setPage] = React.useState(DEFAULT_PAGE);
  const [dataTable, setDataTable] = React.useState([]);
  const [search, setSearch] = React.useState({});
  const [selected, setSelected] = React.useState(false);
  const setLoading = SystemStoreLoading((state) => state.setLoading);
  const [hasError, setHasError] = React.useState(false);
  const user = SystemStoreUser((state) => state.user);
  const [hasSearch, setHasSearch] = React.useState(false);

  React.useEffect(() => {
    setSelected(false);
    ProcessLoadDataTable(
      setDataTable,
      search,
      page,
      setLoading,
      setHasError,
      hasSearch
    );
  }, [search, page]);

  React.useEffect(() => {
    let loadParams = JSON.parse(sessionStorage.getItem("process_search"));
    setPage(loadParams?.page_index);
  }, []);

  const isShowCheckBox =
    dataTable?.data?.some((row) => row?.approver_id === user?.user_id) &&
    FunctionCheckIsSuperAdmin(user);

  return (
    <div className="px-90px flex-1">
      <BreadCrumbMain />
      <ProcessSummaryControllerMemo
        setSearch={setSearch}
        hasError={hasError}
        setPage={setPage}
        setHasSearch={setHasSearch}
      />
      <div className="h-4"></div>
      <ProcessTable
        setPage={setPage}
        page={page}
        header={[
          {
            label:
              user?.role === "BU APPROVAL" || isShowCheckBox ? (
                <FieldCheckBox
                  isChecked={selected}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelected(!selected);
                  }}
                />
              ) : (
                <div></div>
              ),
            key: "selected",
          },
          { label: "No", key: "no", textAlign: "text-left" },
          {
            label: "Process ID",
            key: "sub_process_id",
            textAlign: "text-left",
          },
          {
            label: "Main Process Name",
            key: "process_name",
            textAlign: "text-left",
          },
          {
            label: "Sub Process Name",
            key: "sub_process_name",
            textAlign: "text-left",
          },
          {
            label: "Registered by",
            key: "request_name",
            textAlign: "text-center",
          },
          {
            label: "Lastest Update",
            key: "last_upd_datetime",
            textAlign: "text-center",
          },
          {
            label: "Process Status",
            key: "tran_status",
            textAlign: "text-center",
          },
          { label: "Action", key: "action", textAlign: "" },
        ]}
        data={dataTable?.data}
        pagination={dataTable?.pagination}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};
export default ProcessSummary;
