import React from "react";
import { SystemGlobalOptions } from "../systems/Systems.GlobalOptions";
import api from "./useApi";
import {
  TYPE_APPROVED,
  TYPE_APPROVED_LABEL,
  TYPE_COMPLETED,
  TYPE_COMPLETED_LABEL,
  TYPE_NEW,
  TYPE_NEW_LABEL,
  TYPE_REJECTED,
  TYPE_REJECTED_LABEL,
  TYPE_UPDATED,
  TYPE_UPDATED_LABEL,
} from "./useConstants";

const mockOptions = [
  {
    label: "Please Select",
    value: "",
  },
  {
    label: "Value A",
    value: "01",
  },
  {
    label: "Value B",
    value: "02",
  },
  {
    label: "Value C",
    value: "03",
  },
  {
    label: "Value D",
    value: "04",
  },
  {
    label: "Value E",
    value: "05",
  },
  {
    label: "Value F",
    value: "06",
  },
  {
    label: "Value G",
    value: "07",
  },
];

export const OPTINOS_PROCESS_CATEGORY = mockOptions;

export const OPTINOS_PROCESS_STATUS = [
  {
    label: "All",
    value: "All",
  },
  {
    label: TYPE_NEW_LABEL,
    value: TYPE_NEW,
  },
  {
    label: TYPE_UPDATED_LABEL,
    value: TYPE_UPDATED,
  },
  {
    label: TYPE_APPROVED_LABEL,
    value: TYPE_APPROVED,
  },
  {
    label: TYPE_REJECTED_LABEL,
    value: TYPE_REJECTED,
  },
  {
    label: TYPE_COMPLETED_LABEL,
    value: TYPE_COMPLETED,
  },
];

export const OPTIONS_IT_APPLICATION = () => {
  const [optinos, setOptions] = React.useState([]);

  React.useEffect(() => {
    FunctionLoadDataApplications();
  }, []);

  const FunctionLoadDataApplications = async () => {
    try {
      const {
        data: { data },
      } = await api.api_master_applocation();
      const thisOptions = data?.map((row) => {
        return {
          label: row.app_name,
          value: row.app_id,
        };
      });
      setOptions(thisOptions);
    } catch (error) {
      console.error("ERROR TO LOAD DATA APPLICATION", error);
    }
  };

  return optinos;
};

export const OPTIONS_PROCESS_TYPE = () => {
  const [optinos, setOptions] = React.useState([]);

  const globalOptions = SystemGlobalOptions((state) => state.globalOptions);
  React.useEffect(() => {
    FunctionLoadData();
  }, []);

  const FunctionLoadData = async () => {
    try {
      const data = globalOptions?.PROCESS_CAT; // api_master_process_cat

      const thisOptions = data?.map((row) => {
        return {
          label: `${row.setting_key} : ${row.setting_value}`,
          value: row.setting_key,
          ...row,
        };
      });

      setOptions(thisOptions);
    } catch (error) {
      console.error("ERROR TO LOAD DATA APPLICATION", error);
    }
  };

  return optinos;
};

export const OPTIONS_PROCESS_CREATE_SUBPROCESS = (oc_code) => {
  const [optinos, setOptions] = React.useState([]);

  React.useEffect(() => {
    FunctionLoadData();
  }, []);

  const FunctionLoadData = async () => {
    try {
      const {
        data: { data },
      } = await api.api_master_process(oc_code);

      const thisOptions = data?.map((row) => {
        return {
          label: row.process_name,
          value: row.process_id,
          ...row,
        };
      });
      setOptions(thisOptions);
    } catch (error) {
      console.error("ERROR TO LOAD DATA APPLICATION", error);
    }
  };

  return optinos;
};

export const DATA_SUB_PROCESS = () => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    FunctionLoadData();
  }, []);

  const FunctionLoadData = async () => {
    try {
      const {
        data: { data },
      } = await api.api_master_subprocess();
      setData(data);
    } catch (error) {
      console.error("ERROR TO LOAD DATA APPLICATION", error);
    }
  };

  return data;
};

export const OPTIONS_SUB_PROCESS = (id) => {
  const [optinos, setOptions] = React.useState([]);
  const globalOptions = SystemGlobalOptions((state) => state.globalOptions);

  // React.useEffect(() => {
  //   if (id) {
  //     FunctionLoadData();
  //   } else {
  //     setOptions([]);
  //   }
  // }, [id]);

  React.useEffect(() => {
    FunctionLoadData();
  }, []);

  const FunctionLoadData = async () => {
    try {
      const data = globalOptions?.SUB_PROCESS_TYPE; // api_master_sub_process_type
      const thisOptions = data?.map((row) => {
        return {
          label: row.setting_value,
          value: row.setting_key,
          ...row,
        };
      });
      setOptions(thisOptions);
    } catch (error) {
      console.error("ERROR TO LOAD DATA APPLICATION", error);
    }
  };

  return optinos;
};

export const OPTIONS_PRODUCT = () => {
  const [optinos, setOptions] = React.useState([]);

  React.useEffect(() => {
    FunctionLoadData();
  }, []);

  const FunctionLoadData = async () => {
    try {
      const {
        data: { data },
      } = await api.api_master_products();
      const thisOptions = data?.map((row) => {
        return {
          label: row.product_name,
          value: row.product_id,
        };
      });
      setOptions(thisOptions);
    } catch (error) {
      console.error("ERROR TO LOAD DATA APPLICATION", error);
    }
  };

  return optinos;
};

export const OPTIONS_DIVISION_OWNER = (loadData, isMyRequest = false) => {
  const [optinos, setOptions] = React.useState([]);

  const globalOptions = SystemGlobalOptions((state) => state.globalOptions);

  React.useEffect(() => {
    FunctionLoadData();
  }, [loadData, globalOptions]);

  const FunctionLoadData = async () => {
    try {
      const data = globalOptions?.organiztions;
      const thisKeys = Object.keys(data);
      const thisOptions = thisKeys?.map((row, index) => {
        return {
          index: index,
          label: `${row} Organization`,
          options: data[row]?.map((jow) => {
            return {
              label: jow.name,
              value: jow.id,
            };
          }),
        };
      });
      setOptions(thisOptions?.sort((a, b) => b.index - a.index));
    } catch (error) {
      console.error("ERROR TO LOAD DATA APPLICATION", error);
    }
  };

  // const FunctionLoadData = async () => {
  //   if (count >= 1) {
  //     if (!isStatusRejected && !isStatusNew && !isStatusUpdate) {
  //       const thisOptions = [
  //         {
  //           index: 0,
  //           label: "Division Owner",
  //           options: [
  //             {
  //               label:
  //                 loadData?.oc_code + " - " + loadData?.division_owner_name,
  //               value: loadData?.division_owner,
  //             },
  //           ],
  //         },
  //       ];
  //       setOptions(thisOptions);
  //       return;
  //     } else {
  //       try {
  //         const {
  //           data: { data },
  //         } = await api.api_master_organiztions();
  //         const thisKeys = Object.keys(data);
  //         const thisOptions = thisKeys?.map((row, index) => {
  //           return {
  //             index: index,
  //             label: `${row} Organization`,
  //             options: data[row]?.map((jow) => {
  //               return {
  //                 label: jow.name,
  //                 value: jow.id,
  //               };
  //             }),
  //           };
  //         });
  //         setOptions(thisOptions?.sort((a, b) => b.index - a.index));
  //       } catch (error) {
  //         console.error("ERROR TO LOAD DATA APPLICATION", error);
  //       }
  //     }
  //   } else {
  //     setCount(count + 1);
  //   }
  // };

  return optinos;
};

export const OPTIONS_APPROVER = (id, loadData, isMyRequest = false) => {
  const [optinos, setOptions] = React.useState([]);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    if (id) {
      FunctionLoadData();
    } else {
      setOptions([]);
    }
  }, [id, loadData]);

  const isStatusRejected = loadData?.tran_status === TYPE_REJECTED;
  const isStatusUpdate = loadData?.tran_status === TYPE_UPDATED;
  const isStatusNew =
    loadData?.tran_status === undefined ||
    (loadData?.tran_status === TYPE_NEW && isMyRequest);

  const FunctionLoadData = async () => {
    if (count >= 1) {
      if (!isStatusRejected && !isStatusNew && !isStatusUpdate) {
        const thisOptions = [
          { label: loadData.approver_name, value: loadData.approver_name },
        ];
        setOptions(thisOptions);
      } else {
        try {
          const {
            data: { data },
          } = await api.api_master_employee(id);
          const thisOptions = data?.map((row) => {
            return {
              label: row.emp_name_eng,
              value: row.emp_name_eng,
              ...row,
            };
          });
          setOptions(thisOptions);
        } catch (error) {
          console.error("ERROR TO LOAD DATA APPLICATION", error);
        }
      }
    } else {
      setCount(count + 1);
    }
  };

  return optinos;
};

export const OPTIONS_INPUT_TYPE = mockOptions;

export const OPTIONS_FREQUENCY = () => {
  const [optinos, setOptions] = React.useState([]);
  const globalOptions = SystemGlobalOptions((state) => state.globalOptions);

  React.useEffect(() => {
    FunctionLoadData();
  }, []);

  const FunctionLoadData = async () => {
    try {
      const data = globalOptions?.FREQUENCY; //api_master_frequency

      const thisOptions = data?.map((row) => {
        return {
          label: row?.setting_value,
          value: row?.setting_key,
          ...row,
        };
      });
      setOptions(thisOptions);
    } catch (error) {
      console.error("ERROR TO LOAD DATA APPLICATION", error);
    }
  };

  return optinos;
};

export const OPTIONS_OUTPUT_TYPE = mockOptions;

export const OPTIONS_SELECT_MODAL_TYPE = ["Main Process", "Sub Process"];

export const OPTIONS_OCCODE = () => {
  const [optinos, setOptions] = React.useState([]);

  React.useEffect(() => {
    FunctionLoadDataOcCode();
  }, []);

  const FunctionLoadDataOcCode = async () => {
    try {
      const {
        data: { data },
      } = await api.api_master_organiztions();

      const thisOptions = data?.others?.map((row) => {
        return {
          label: row.oc_code,
          value: row.oc_code ? Number(row.oc_code) : "",
        };
      });
      setOptions(thisOptions);
    } catch (error) {
      console.error("ERROR TO LOAD DATA APPLICATION", error);
    }
  };

  return optinos;
};

export const OPTION_IN_AND_OUT = () => {
  const [optinos, setOptions] = React.useState([]);

  const globalOptions = SystemGlobalOptions((state) => state.globalOptions);

  React.useEffect(() => {
    FunctionLoadData();
  }, []);

  const FunctionLoadData = async () => {
    try {
      const data = globalOptions?.IO_TYPE; //api_master_in_and_out

      const thisOptions = data?.map((row) => {
        return {
          label: row?.setting_value,
          value: row?.setting_key,
          ...row,
        };
      });
      setOptions(thisOptions);
    } catch (error) {
      console.error("ERROR TO LOAD DATA APPLICATION", error);
    }
  };

  return optinos;
};
