import { Controllers } from "radium-omnibus";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FieldSelectIsSearchAble } from "../../../components/fields/Field.Select";
import ModalMain from "../../../components/modal/Modal.Main";
import StateComponent from "../../../components/state/State.Component";
import { SystemStoreUser } from "../../../systems/Systems.StorageUser";
import { CONSTANT_PAGE_SUMMARY } from "../../../utils/useConstants";
import {
  OPTIONS_PROCESS_CREATE_SUBPROCESS,
  OPTIONS_SELECT_MODAL_TYPE,
} from "../../../utils/useOptions";

const ProcessButtonNewProcessControllerMemo = React.memo(
  ({ setOpen, options, navigate }) => {
    return (
      <Controllers
        showError={false}
        showSymbol={false}
        fields={[
          {
            name: "process_type",
            direction: "horizontal",
            type: "radio",
            options: OPTIONS_SELECT_MODAL_TYPE,
            classNameCore: "text-32px my-8 ",
            classNameInput: "accent-scbPrimary w-5 h-5",
            classNameWrapper: "space-x-4",
            required: true,
            emitter: "process_name",
            initalValue: "Main Process",
          },
          {
            type: "customs",
            classNameLabel: "w-full mt-2",
            classNameCore: "text-32px",
            classNameWrapper: "flex space-x-4 mb-10 w-full",
            resetValueWhenEmitterChange: true,
            label: CONSTANT_PAGE_SUMMARY.LABEL_MAIN_PROCESS_NAME,
            name: "process_name",
            emitter: "process_type",
            recipient: true,
            fieldCustoms: (props) => {
              const isDisabled =
                props?.received === "Sub Process" ? false : true;
              return (
                <div className="w-[800px]">
                  <FieldSelectIsSearchAble
                    options={options}
                    props={props}
                    isDisabled={isDisabled}
                    placeholder={
                      <div className="italic">
                        {CONSTANT_PAGE_SUMMARY.SELECT_PROCESS_NAME_PLACEHOLDER}
                      </div>
                    }
                  />
                </div>
              );
            },
          },
          // {
          //   name: "process_name",
          //   label: CONSTANT_PAGE_SUMMARY.LABEL_MAIN_PROCESS_NAME,
          //   classNameCore: "text-32px ",
          //   type: "select",
          //   classNameLabel: "w-full",
          //   classNameWrapper: "flex space-x-4 mb-10",
          //   classNameInput: "w-2/3 relative",
          //   classNameOption: STYLE_SELECT_OPTION,
          //   classNameOptions: STYLE_SELECT_OPTIONS,
          //   resetValueWhenEmitterChange: true,
          //   classNameValue: (props) => {
          //     if (props?.received === "Sub Process") {
          //       return STYLE_SELECT_VALUE + " text-28px";
          //     } else {
          //       return STYLE_SELECT_VALUE + " bg-mediumGray text-28px ";
          //     }
          //   },
          // emitter: "process_type",
          // recipient: true,
          //   options: options,
          // disabled: (props) => {
          //   if (props?.received === "Sub Process") {
          //     return false;
          //   } else {
          //     return true;
          //   }
          // },
          // },
        ]}
        onSubmit={(data) => {
          const thisValue = options.find(
            (item) => item.value === data.process_name
          );
          sessionStorage.setItem("process", JSON.stringify(thisValue));
          if (
            data?.process_type === "Sub Process" &&
            data?.process_name === ""
          ) {
            alert(CONSTANT_PAGE_SUMMARY.ALERT_PLEASE_SELECT);
            return;
          }
          navigate("/process_create_and_maintenance/process/create");
        }}
        onCancel={() => {
          setOpen(false);
        }}
        labelSubmit={CONSTANT_PAGE_SUMMARY.LABEL_SUBMIT}
        classNameSubmit="bg-scbPrimary w-130px text-24px rounded shadow-lg text-white p-1  cursor-pointer"
        classNameCancel="border  w-130px rounded shadow-lg text-24px text-scbPrimary p-1 cursor-pointer"
        classNameWrapper="flex justify-center gap-2"
        labelCancel={CONSTANT_PAGE_SUMMARY.LABEL_CANCEL}
      />
    );
  }
);

const ProcessButtonNewProcess = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const user = SystemStoreUser((state) => state.user);

  return (
    <React.Fragment>
      <div
        onClick={() => {
          setOpen(true);
        }}
        className="bg-scbPrimary text-30px h-16  px-4 pt-2 rounded-lg font-bold shadow-lg text-center text-white cursor-pointer  "
        // className="bg-scbPrimary text-30px h-16  px-4 pt-2 rounded text-center text-white cursor-pointer  "
      >
        {CONSTANT_PAGE_SUMMARY.NEW_BUTTON}
      </div>
      {open && (
        <ModalMain
          open={open}
          setOpen={setOpen}
          styleModal={{
            height: "200px",
          }}
          labelSubmit={CONSTANT_PAGE_SUMMARY.LABEL_SUBMIT}
          body={
            <StateComponent
              body={() => {
                const options = OPTIONS_PROCESS_CREATE_SUBPROCESS(user?.org_id);
                return (
                  <ProcessButtonNewProcessControllerMemo
                    setOpen={setOpen}
                    options={options}
                    navigate={navigate}
                  />
                );
              }}
            />
          }
          title={CONSTANT_PAGE_SUMMARY.MODAL_TITLE}
          classNameTitle="text-32px font-bold text-center mx-auto"
          footer={false}
        />
      )}
    </React.Fragment>
  );
};

export default ProcessButtonNewProcess;
