import { useLocation } from "react-router-dom";
import NavbarModalUser from "./render/Navbar.ModalUser";

const NavbarMain = () => {
  const location = useLocation();

  if (location.pathname === "/login") return null;
  return (
    <div className="flex justify-between pl-90px pr-74px h-142px bg-scbPrimary">
      <div className="flex flex-row">
        <img
          src="/images/logo.png"
          className="h-[42px] w-[42px] my-auto"
          alt="user"
        />
        {/* <div className="flex flex-col "> */}
        <div className="text-white font-bold text-[50px] ml-4 my-auto">
          SCB | ORDP
        </div>
        {/* <div className="text-white font-bold text-[25px] ml-4  mb-auto">
            PROCESS CREATE AND MAINTENANCE
          </div> */}
      </div>
      {/* </div> */}

      <NavbarModalUser />
    </div>
  );
};

export default NavbarMain;
