import { useNavigate } from "react-router-dom";
import { FunctionFormatNewLine } from "../../../utils/useFunctions";

const HomeCard = ({ image, name, path, disabled, showNotif, countNoti }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        if (!disabled) {
          if (path === "/process_create_and_maintenance/summary") {
            sessionStorage.setItem("check-status", "reset");
          }
          navigate(`${path}`);
        } else {
          return;
        }
      }}
      className={`${
        disabled ? " opacity-100 text-gray-300 " : " cursor-pointer "
      } p-5 border  rounded-3xl shadow-md border-gray-300 hover:border-borderSCB hover:border-4	 `}
    >
      {showNotif && (
        <div className=" relative mx-auto h-[36px] w-[36px] bg-red rounded-full  text-white text-center text-25px -mt-2 pb-4	">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  ">
            {countNoti}
          </div>
        </div>
      )}
      <img className="h-48" alt={`image-${path}`} src={image} />
      {!showNotif && <div className="h-5" />}
      <div className="text-25px font-bold text-center w-full">
        {FunctionFormatNewLine(name.toUpperCase())}
      </div>
    </div>
  );
};
export default HomeCard;
