import React from "react";
import { CONSTANT_PAGE_PROCESS } from "../../../utils/useConstants";

const HeaderRejected = ({ data }) => {
  if (data?.reject_reasons?.length > 0) {
    const { reject_reasons } = data;
    return (
      <div className="border border-red mt-2 p-2 px-5 text-lightBlack rounded-3xl break-all">
        <div className="text-32px">{CONSTANT_PAGE_PROCESS.HEADER_REJECT}</div>
        <div className="p-2 text-24px px-10">
          <div>
            {reject_reasons?.map((row, index) => {
              // const { reject_reasons: thisData } = row;
              // if (thisData) {
              //   const data = thisData[0];
              return (
                <div key={`key-of-rejcted-${index}`}>
                  {`<`}
                  {row?.date}
                  {`>`} - {`<`}
                  {row?.description}
                  {`>`} - {`<`}
                  {row?.approver}
                  {`>`}
                </div>
              );
              // } else {
              //   return <React.Fragment key={`key-of-rejcted-${index}`} />;
              // }
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return <React.Fragment />;
  }
};

export default HeaderRejected;
