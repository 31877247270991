import api from "../../../utils/useApi";

const HomeLoadNoti = async (setValue) => {
  try {
    const {
      data: {
        data: { pending },
      },
    } = await api.api_master_notification();
    setValue(pending);
  } catch (error) {
    console.error("ERROR  TO LOAD NOTI ", error);
  }
};
export default HomeLoadNoti;
